<form [formGroup]="interiorExteriorFormGroup">
    <h2>{{'property-adding.interior' | translate}}</h2>
    <div class="d-flex col">
        <mat-form-field appearance="fill" class="col-4">
            <mat-label for="livingSpaceM2">{{'property-adding.living-space' | translate}}</mat-label>
            <input matInput formControlName="livingSpaceM2" id="livingSpaceM2" type="number">
            <span matTextSuffix>m²</span>
            <div *ngIf="fieldIsEmpty('livingSpaceM2')" class="errors">
                {{'errors.living-space-field' | translate}}
            </div>
            <div *ngIf="formControls['livingSpaceM2'].touched && formControls['livingSpaceM2'].invalid" class="errors">
                <div *ngIf="formControls[ 'livingSpaceM2'].errors && (formControls[ 'livingSpaceM2'].errors[ 'pattern'] || formControls[ 'livingSpaceM2'].errors[ 'invalidLivingSpace'])">
                    {{'errors.invalid-livingSpace' | translate : {minLivingSpace: minLivingSpaceM2, maxLivingSpace: maxLivingSpaceM2} }}</div>
            </div>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-4">
            <mat-label for="nbOfRooms">{{'property-adding.nb-of-rooms' | translate}}</mat-label>
            <input matInput formControlName="nbOfRooms" id="nbOfRooms" type="number">
            <div *ngIf="fieldIsEmpty('nbOfRooms')" class="errors">
                {{'errors.rooms-number-field' | translate}}
            </div>
            <div *ngIf="formControls['nbOfRooms'].touched && formControls['nbOfRooms'].invalid" class="errors">
                <div *ngIf="formControls[ 'nbOfRooms'].errors && (formControls[ 'nbOfRooms'].errors[ 'pattern'] || formControls[ 'nbOfRooms'].errors[ 'invalidNbOfRooms'])">
                    {{'errors.invalid-nbOfRooms' | translate : {minNbOfRooms: minNbOfRooms, maxNbOfRooms: maxNbOfRooms} }}</div>
            </div>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-4">
            <mat-label for="kitchenType">{{'property-adding.kitchen-type' | translate}}</mat-label>
            <mat-select formControlName="kitchenType">
                <mat-option *ngFor="let type of kitchenTypes" [value]="type">
                    {{'option.' + type | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-label for="hasAttic">{{'property-adding.attic' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasAttic">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-label for="hasCellar">{{'property-adding.cellar' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasCellar">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-label for="hasFurnitures">{{'property-adding.furnitures' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasFurnitures">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>

    <h2>{{'property-adding.exterior' | translate}}</h2>
    <div class="d-flex col">
        <mat-form-field appearance="fill" col="4">
            <mat-label for="landArea">{{'property-adding.land-area' | translate}}</mat-label>
            <input matInput formControlName="landArea" id="landArea" type="number">
            <span matTextSuffix>m²</span>
            <div *ngIf="formControls['landArea'].touched && formControls['landArea'].invalid" class="errors">
                <div *ngIf="formControls[ 'landArea'].errors && (formControls['landArea'].errors[ 'pattern'] || formControls[ 'landArea'].errors[ 'invalidLandArea'])">
                    {{'errors.invalid-landArea' | translate : {minLandArea: minLandArea, maxLandArea: maxLandArea} }}</div>
            </div>
        </mat-form-field>

        <mat-form-field appearance="fill" col="4">
            <mat-label for="parkingPlaces">{{'property-adding.parking-places' | translate}}</mat-label>
            <input matInput formControlName="parkingPlaces" id="parkingPlaces" type="number">
            <div *ngIf="formControls['parkingPlaces'].touched && formControls['parkingPlaces'].invalid" class="errors">
                <div *ngIf="formControls[ 'parkingPlaces'].errors && (formControls[ 'parkingPlaces'].errors[ 'pattern'] || formControls[ 'parkingPlaces'].errors[ 'invalidParkingPlaces'])">
                    {{'errors.invalid-parkingPlaces' | translate : {minPlaces: minParkingSpaces, maxPlaces: maxParkingSpaces} }}</div>
            </div>
        </mat-form-field>
    </div>

    <div class="d-block">
        <div class="d-block">
            <mat-label for="hasGarden">{{'property-adding.garden' | translate}}</mat-label>
            <mat-radio-group class="form-group d-block" formControlName="hasGarden" id="garden">
                <mat-radio-button [value]="true" color="primary">{{ 'option.yes' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field appearance="fill" *ngIf="hasGarden === true">
            <mat-label for="gardenArea">{{'property-adding.garden-area' | translate}}</mat-label>
            <input matInput formControlName="gardenArea" id="gardenArea" type="text">
            <span matTextSuffix>m²</span>
        </mat-form-field>
    </div>

    <div class="d-block">
        <div class="d-block">
            <mat-label for="hasTerrace">{{'property-adding.terrace' | translate}}</mat-label>
            <mat-radio-group class="form-group d-block" formControlName="hasTerrace" id="terrace">
                <mat-radio-button [value]="true" color="primary">{{ 'option.yes' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field appearance="fill" *ngIf="terrace === true">
            <mat-label for="terraceArea">{{'property-adding.terrace-area' | translate}}</mat-label>
            <input matInput formControlName="terraceArea" id="terraceArea" type="text">
            <span matTextSuffix>m²</span>
        </mat-form-field>
    </div>


</form>