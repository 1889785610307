
export class ApplicationRoute {
    static readonly HOME: ApplicationRoute = new ApplicationRoute("");
    static readonly GOODS: ApplicationRoute = new ApplicationRoute('goods');
    static readonly ABOUT_US: ApplicationRoute = new ApplicationRoute('about-us');
    static readonly CONTACT: ApplicationRoute = new ApplicationRoute('contact');
    static readonly CREATE: ApplicationRoute = new ApplicationRoute('create');
    static readonly EDIT_PROFILE: ApplicationRoute = new ApplicationRoute('profile/edit');
    static readonly PROFILE: ApplicationRoute = new ApplicationRoute('profile');
    static readonly CANDIDATES: ApplicationRoute = new ApplicationRoute('candidates');
    static readonly APPLY_IN_GOOD: ApplicationRoute = new ApplicationRoute('apply');
    static readonly MY_GOODS: ApplicationRoute = new ApplicationRoute('my-goods');
    static readonly EDIT_GOODS: ApplicationRoute = new ApplicationRoute('edit');
    static readonly OWNERS_ROUTE: ApplicationRoute = new ApplicationRoute('owners');
    static readonly MY_OPPOINTMENTS_ROUTE: ApplicationRoute = new ApplicationRoute('my-appointments');
    static readonly MY_APPLICATION_ROUTE: ApplicationRoute = new ApplicationRoute('my-applications');
    static readonly INVENTORIES_ROUTE: ApplicationRoute = new ApplicationRoute('inventories');
    static readonly ERROR: ApplicationRoute = new ApplicationRoute('error');
    static readonly UNKNOWN: ApplicationRoute = new ApplicationRoute('**');
    static readonly FAVORITES: ApplicationRoute = new ApplicationRoute('favorites');
    static readonly USERS: ApplicationRoute = new ApplicationRoute('users');
    static readonly PUBLIC: ApplicationRoute = new ApplicationRoute('public');
    static readonly SCHEDULE: ApplicationRoute = new ApplicationRoute('schedule');
    static readonly APPLY_GOOD: ApplicationRoute = new ApplicationRoute('goods/{id}/apply');
    static readonly MY_FAVORITES: ApplicationRoute = new ApplicationRoute('public/goods');
    static readonly MESSAGES: ApplicationRoute = new ApplicationRoute('messages');

    static readonly LEGAL_MENTIONS: ApplicationRoute = new ApplicationRoute("legal-mentions");
    static readonly CGU: ApplicationRoute = new ApplicationRoute("cgu");
    static readonly RGPD: ApplicationRoute = new ApplicationRoute("rgpd");

    constructor (private readonly value: string) {
    }

    get navigationPath(): string {
        return '/' + this.path;
    }
    navigationPathWithId(id: number): string {
        return '/' + this.path.replace("{id}", id.toString());
    }

    get path(): string {
        return this.value;
    }

    navigationPathWithParam(param: string) {
        return '/' + this.path + param;
    }
}