<form [formGroup]="filesFormGroup">
    <h2 id="files-title">{{'goods.apply-form.stepper.files-title' | translate}}</h2>

    <app-upload-doc-file (uploadFile)="getAndSetUploadedFilesInput($event)" [title]="'goods.apply-form.household-composition'" [controlName]="'householdComposition'" [maxFilesNumber]="1"></app-upload-doc-file>
    <div>
        <div *ngIf="formControls['householdComposition'].touched && formControls['householdComposition'].invalid" class="alert alert-danger">
            <div *ngIf="formControls['householdComposition'].errors && formControls['householdComposition'].errors['required']">
                {{ 'error-message.householdComposition-error' | translate }}</div>
        </div>
    </div>
    <app-upload-doc-file (uploadFile)="getAndSetUploadedFilesInput($event)" [title]="'goods.apply-form.salary-files'" [controlName]="'salaryFiles'" [maxFilesNumber]="1"></app-upload-doc-file>
    <div>
        <div *ngIf="formControls['salaryFiles'].touched && formControls['salaryFiles'].invalid" class="alert alert-danger">
            <div *ngIf="formControls['salaryFiles'].errors && formControls['salaryFiles'].errors['required']">
                {{ 'error-message.salaryFiles-error' | translate }}</div>
        </div>
    </div>
    <app-upload-doc-file (uploadFile)="getAndSetUploadedFilesInput($event)" [title]="'goods.apply-form.proof-of-payment'" [controlName]="'proofOfPayment'" [maxFilesNumber]="1"></app-upload-doc-file>
    <div>
        <div *ngIf="formControls['proofOfPayment'].touched && formControls['proofOfPayment'].invalid" class="alert alert-danger">
            <div *ngIf="formControls['proofOfPayment'].errors && formControls['proofOfPayment'].errors['required']">
                {{ 'error-message.proofOfPayment-error' | translate }}</div>
        </div>
    </div>
</form>