import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from "@angular/forms";
import { UploadedFile } from "../../../_shared-components/upload-doc-file/upload-doc-file.component";
import { NgxFileDropEntry } from "ngx-file-drop";
import { GlobalFileService } from "../../services/global-file.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { FileExtension } from "../../../../shared/enums/file-extension";
import { AppConstants } from "../../../../shared/constants/app.constants";

@Component({
    selector: 'app-attachments-form',
    templateUrl: './attachments-form.component.html',
    styleUrls: ['./attachments-form.component.scss']
})
export class AttachmentsFormComponent {
    @Input()
    formGroup!: FormGroup;

    uploadOnGoing = false;

    readonly maxNumberOfFileUploads: number = AppConstants.GOODS_MAX_FILE_UPLOADS;

    readonly maxFileSizeInMb: number = AppConstants.GOODS_MAX_FILE_SIZE_IN_MB;

    private uploadedFiles: UploadedFile[] = [];
    selectedImagePreviews: string[] = [];
    selectedMainImageIndex: number | null = null;
    nbRequestedFiles = 0;

    constructor(
        private readonly globalFileService: GlobalFileService,
        private readonly translate: TranslateService,
        private readonly snackBar: MatSnackBar,
    ) { }

    uploadFile(files: NgxFileDropEntry[]): void {
        this.nbRequestedFiles = this.nbRequestedFiles + files.length;
        if (this.nbRequestedFiles > this.maxNumberOfFileUploads) {
            const msg = this.translate.instant('property-adding.too-many-files');
            this.snackBar.open(msg, '', { duration: 1500 });
            return;
        }
    
        this.uploadOnGoing = true;
        let filesLeft = files.length;

        files.forEach(file => {
            const fileEntry = file.fileEntry as FileSystemFileEntry;
            fileEntry.file(file => {
                const blob = new Blob([file], { type: file.type });

                const fileFromBlob = new File([blob], file.name, { lastModified: file.lastModified, type: file.type });

                this.globalFileService.upload(fileFromBlob)
                    .subscribe({
                        next: (uploadedFile: UploadedFile) => {
                            this.uploadedFiles = [...this.uploadedFiles, uploadedFile];
                            this.fileFormControl?.setValue(this.uploadedFileIds);
                            this.globalFileService.onUploaded.next(this.uploadedFiles);

                            const reader = new FileReader();
                            reader.onload = (e) => {
                                const imagePreview = e.target?.result as string;
                                this.selectedImagePreviews.push(imagePreview);
                            };
                            reader.readAsDataURL(blob);
                        },
                        error: () => {
                            this.handleUploadComplete(--filesLeft);
                            const msg = `${this.translate.instant('failed-file-up')} '${file.name}'`;
                            this.snackBar.open(msg, '', { duration: 1500 });
                        },
                        complete: () => this.handleUploadComplete(--filesLeft),
                    });
            });
        });
    }

    private handleUploadComplete(filesLeft: number): void {
        if (filesLeft <= 0) {
            this.uploadOnGoing = false
        }
    }

    deleteFile(file: UploadedFile | NgxFileDropEntry): void {
        const deletedId = (file as UploadedFile).id;
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== deletedId);
        this.fileFormControl?.setValue(this.uploadedFileIds);
        this.globalFileService.delete(deletedId);
    }

    get fileFormControl(): AbstractControl<any, any> | null {
        return this.formGroup.get('imageFileIds');
    }

    get mainImageFormControl(): AbstractControl<any, any> | null {
        return this.formGroup.get('mainImageFileId');
    }

    get uploadedFileIds(): number[] {
        return this.uploadedFiles.map(file => file.id);
    }

    get mainImageFileId(): number | null {
        return this.selectedMainImageIndex ? this.uploadedFileIds[this.selectedMainImageIndex] : null;
    }

    get acceptedFileExtensions(): FileExtension[] {
        return [
            FileExtension.JPG,
            FileExtension.JPEG,
            FileExtension.PNG,
            FileExtension.GIF,
            FileExtension.SVG,
            FileExtension.WEBP
        ];
    }

    deletePreview(index: number): void {
        this.uploadedFiles.splice(index, 1);
        this.fileFormControl?.setValue(this.uploadedFileIds);
        this.selectedImagePreviews.splice(index, 1);
    }

    toggleMainImage(index: number) {
        if (this.selectedMainImageIndex === index) {
            this.selectedMainImageIndex = null;
        } else {
            this.selectedMainImageIndex = index;
        }
        this.mainImageFormControl?.setValue(this.mainImageFileId);
    }

    hasExceededMaxUploads(): boolean {
        const totalPreviewFiles = this.selectedImagePreviews.length;
        const newFilesToAdd = this.uploadedFiles.length;
        return totalPreviewFiles + newFilesToAdd > this.maxNumberOfFileUploads;
    }
}
