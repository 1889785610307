import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ApplicationRoute } from 'src/shared/constants/application-route';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  ApplicationRoute = ApplicationRoute;

  @Input('home-isLoggedIn') 
  isLoggedIn: boolean = false;

  constructor(
    private readonly keycloak: KeycloakService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn(); 
    let element = document.getElementsByClassName('content');
    if(element && element.length > 0) {
      (element[0] as HTMLElement).style.padding = '0';
    }
  }

  ngOnDestroy(): void {
    let element = document.getElementsByClassName('content');
    if(element && element.length > 0) {
      (element[0] as HTMLElement).style.padding = '5em';
    }
  }

}
