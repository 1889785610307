import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PropertyType } from '../../models/enums/property-type.enum';

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss']
})
export class CommonFormComponent {

  PropertyType = PropertyType;


  @Input()
  commonFormGroup!: FormGroup;
  
  isIncorrect(): boolean {
    let correct = (this.commonFormGroup.controls['address'] as FormGroup).controls['postalCode'].invalid 
    && ((this.commonFormGroup.controls['address'] as FormGroup).controls['postalCode'].dirty 
    || (this.commonFormGroup.controls['address'] as FormGroup).controls['postalCode'].valid);
    return correct;
  }

  fieldIsEmpty(formControlName: string): boolean {
    const control = this.commonFormGroup.get(formControlName);
    return control ? control.touched && control.value === '' : true;
  }
  
  descriptionLengthIsNotValid(): boolean {
    const descriptionControl = this.commonFormGroup.get('description');
    if (descriptionControl) {
      const descriptionValue = descriptionControl.value;
      const minLength = 150;
      const maxLength = 1500;
      return descriptionValue.length < minLength || descriptionValue.length > maxLength;
    }
    return false;
  }

}
