import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider'
import { GoodsSearchComponent } from './goods-search.component';
import { GoodSearchFiltersComponent } from './good-search-filters/good-search-filters.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';


@NgModule({
  declarations: [
    GoodsSearchComponent,
    GoodSearchFiltersComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    MatDialogModule,
    MatSelectModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatListModule,
    MatSliderModule,
    FormsModule,
    RouterModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  exports: [
    GoodsSearchComponent
  ]
})
export class GoodsSearchModule { }
