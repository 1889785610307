import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import {HttpClient} from "@angular/common/http";
import {BackendConstants} from "../../shared/constants/backend.constants";
import {User} from "../models/user.model";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        protected readonly keycloak: KeycloakService,
        private readonly http: HttpClient
    ) {}

    getConnectedUserProfile(): Observable<User> {
        return this.http.get<User>(BackendConstants.BACKEND_URL + "/profile")
    }

    logout() {
        this.keycloak.logout().then(() => console.log("The account logged out"));
    }
}