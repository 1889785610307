<button mat-button class="close-btn" (click)="closeDialog()">
    <mat-icon class="delete-icon">close</mat-icon>
</button>
<div class="row m-0 px-4 py-5">
    <div class="col-md-1 p-0">
        <img src="../assets/images/check-green.png" alt="success check">
    </div>
    <div class="col-md-10 pl-0">
        <h2 class="mb-3">{{'good-creation-validation.congratulations' | translate}}</h2>
        <p>
            {{'good-creation-validation.created-good' | translate}}
        </p>
        <p>
            {{'good-creation-validation.not-online' | translate}}
        </p>
    </div>
</div>
<div class="row m-0 my-4">
    <div class="col-6 offset-md-6 text-right">
        <button type="button" matButton class="btn btn-outline" (click)="closeDialog()">{{'good-creation-validation.check-my-good' | translate}}</button>
        <button type="button" matButton class="btn btn-blue" [routerLink]="scheduleRoute" (click)="closeDialog()">{{'good-creation-validation.switch-to-online' | translate}}</button>
    </div>
</div>