<form [formGroup]="calendarFormGroup">
    <h2 id="calendar-title">{{'goods.apply-form.stepper.calendar-title' | translate}}</h2>
    <div class="d-flex col">
        <div class="form-group col-6 m-0 p-0">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label for="visitDate" id="visit-date-label">{{'goods.apply-form.stepper.choose-date' |translate}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="visitDate" id="visit-date" [matDatepickerFilter]="dateFilter" [readonly]="true">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker>
                    <mat-datepicker-actions>
                        <button mat-button matDatepickerCancel id="date-cancel-button">
                        {{'property-adding.date-button.cancel' | translate}}
                    </button>
                        <button mat-raised-button matDatepickerApply id="date-apply-button">
                        {{'property-adding.date-button.apply' | translate}}
                    </button>
                    </mat-datepicker-actions>
                </mat-datepicker>
                <div *ngIf="formControls['visitDate'].touched && formControls['visitDate'].invalid" class="errors">
                    <div *ngIf="formControls['visitDate'].errors && formControls['visitDate'].errors['required']">
                        {{ 'error-message.email-error' | translate }}</div>
                </div>
            </mat-form-field>
        </div>
        <div class="form-group col-6 m-0 p-0" *ngIf="calendarFormGroup.get('visitDate')?.value">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label for="visitHour" id="visit-hour-label">{{'goods.apply-form.stepper.choose-hour' |translate}}</mat-label>
                <mat-select formControlName="visitHour" id="visit-hour" [disabled]="availableHours.length === 0">
                    <mat-option *ngFor="let hour of availableHours" [value]="hour">{{hour}}</mat-option>
                </mat-select>
                <div *ngIf="formControls['visitHour'].touched && formControls['visitHour'].invalid" class="errors">
                    <div *ngIf="formControls['visitHour'].errors && formControls['visitHour'].errors['required']">
                        {{ 'error-message.email-error' | translate }}</div>
                </div>
            </mat-form-field>
        </div>
    </div>

</form>