<div class="socialMedias d-flex">
    <div>
        <a href="https://www.facebook.com/notrebail" target="_blank">
            <img src="../../assets/images/facebook.png" alt="{{'footer.facebook-alt' | translate}}">
        </a>
        <a href="https://www.instagram.com/notrebail/" target="_blank">
            <img src="../../assets/images/instagram.png" alt="{{'footer.instagram-alt' | translate}}">
        </a>
        <a href="https://www.linkedin.com/showcase/notre-bail/about/" target="_blank">
            <img src="../../assets/images/linkedin.png" alt="{{'footer.linkedin-alt' | translate}}">
        </a>
    </div>
</div>