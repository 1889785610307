<div class="d-flex align-items-start menu">
    <mat-list>
        <mat-list-item>
            <button class="icon d-flex btn align-items-center">
                <mat-icon class="icon">grid_view</mat-icon>
                <p>{{ 'navigation-menu.dashboard' | translate }}</p>
            </button>
        </mat-list-item>

         <!-- Owner features -->
        <mat-list-item *ngIf="isOwnerMode" >
            <button [routerLink]="ApplicationRoute.OWNERS_ROUTE.navigationPath + ApplicationRoute.MY_GOODS.navigationPath" class="icon d-flex btn align-items-center">
                <mat-icon class="icon">home</mat-icon>
                <p>{{ 'navigation-menu.my-goods' | translate }}</p>
            </button>
        </mat-list-item>
        <mat-list-item *ngIf="isOwnerMode" >
            <button class="icon d-flex btn align-items-center">
                <mat-icon class="icon">calendar_month</mat-icon>
                <p>{{ 'navigation-menu.appointment' | translate }}</p>
            </button>
        </mat-list-item>

        <!-- Occupant features -->
        <mat-list-item  *ngIf="!isOwnerMode" >
            <button class="icon d-flex btn align-items-center">
                <mat-icon class="icon">home</mat-icon>
                <p>{{ 'navigation-menu.my-rental' | translate }}</p>
            </button>
        </mat-list-item>
        <mat-list-item  *ngIf="!isOwnerMode" >
            <button class="icon d-flex btn align-items-center">
                <mat-icon class="icon">approval_delegation</mat-icon>
                <p>{{ 'navigation-menu.my-applications' | translate }}</p>
            </button>
        </mat-list-item>
        <mat-list-item  *ngIf="!isOwnerMode" >
            <button class="icon d-flex btn align-items-center">
                <mat-icon class="icon">favorite</mat-icon>
                <p>{{ 'navigation-menu.my-favorites' | translate }}</p>
            </button>
        </mat-list-item>

        <!-- Features -->
        <mat-list-item>
            <button class="icon d-flex btn align-items-center">
                <mat-icon class="icon"> mail</mat-icon>
                <p>{{ 'navigation-menu.messages' | translate }}</p>
            </button>
        </mat-list-item>
        <mat-list-item>
            <button [routerLink]="ApplicationRoute.PROFILE.navigationPath" class="icon d-flex btn align-items-center">
                <mat-icon class="icon">account_circle</mat-icon>
                <p>{{ 'navigation-menu.profile' | translate }}</p>
            </button>
        </mat-list-item>
        <mat-list-item>
            <button class=" icon d-flex btn align-items-center">
                <mat-icon>logout</mat-icon>
                <p>{{ 'navigation-menu.logout' | translate }}</p>
            </button>
        </mat-list-item>
    </mat-list>
</div>