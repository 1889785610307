import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return new Promise<boolean>((resolve, reject) => {
      // Force the user to log in if currently unauthenticated.
      if (!this.authenticated) {     
        this.keycloak.login({
          redirectUri: environment.redirect_uri,
        });
        return reject(false);
      }
  
      // Get the roles required from the route.
      const requiredRoles = route.data['roles'];
  
      // Allow the user to proceed if no additional roles are required to access the route.
      if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
        return resolve(true);
      }
  
      // Allow the user to proceed if all the required roles are present.
      return resolve(requiredRoles.every((role) => this.roles.includes(role)));
    });
  }
}