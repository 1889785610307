import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoodService } from '../services/good.service';
import { Good } from '../models/good.model';
import { Candidate } from 'src/app/candidates/models/candidate.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { ApplicationRoute } from "../../../shared/constants/application-route";
import { MatStepper } from '@angular/material/stepper';
import { ApplyFormService } from './service/apply-form.service';
import { MatSnackBarService } from 'src/app/services/matSnackBar.service';
import { GoodApplyForm } from '../models/good-apply-form.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-apply-form-create',
  templateUrl: './apply-form-create.component.html',
  styleUrls: ['./apply-form-create.component.scss'],
})
export class ApplyFormCreateComponent implements OnInit {

  @ViewChild(MatStepper)
  stepper!: MatStepper;

  good!: Good;
  candidate!: Candidate;
  infoFormGroup!: FormGroup;
  filesFormGroup!: FormGroup;
  calendarFormGroup!: FormGroup;

  tempApplyFormId!: string;

  formJoin?:FormGroup;

  constructor(private readonly fb: FormBuilder,
    private readonly goodService: GoodService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    readonly applyFormService: ApplyFormService,
    private readonly matSnackBarService: MatSnackBarService,
    readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getGood();
    this.initForm();
  }

  ngAfterViewInit(): void { 
    this.applyFormService.stepper = this.stepper;
  }

  getGood(): void {
    this.activatedRoute.params.subscribe(param => {
      const id = param['id'];
      if (isNaN(id)) {
        this.router.navigateByUrl(ApplicationRoute.GOODS.navigationPath);
      }
      this.goodService.getGood(id).subscribe({
        next: (good) => this.good = good,
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.router.navigateByUrl(ApplicationRoute.GOODS.navigationPath);
          }
        }
      });
    });
  }

  initForm(): void {
    this.infoFormGroup = this.fb.group({
      fullName: ['', [Validators.required, Validators.maxLength(50)]],
      phoneNumber: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required, Validators.maxLength(150)]],
      civilState: ['', Validators.required]
    });
    this.filesFormGroup = this.fb.group({
      householdComposition: [''],
      salaryFiles: [''],
      proofOfPayment: ['']
    });
    this.calendarFormGroup = this.fb.group({
      visitDate: ['', Validators.required],
      visitHour: ['', Validators.required]
    })
  }

  goToNextStep(): void {
    const currentStep = this.stepper.selectedIndex;
    let currentFormGroup: FormGroup | undefined;
    switch (currentStep) {
      case 0:
        currentFormGroup = this.infoFormGroup;
        break;
      case 1:
        currentFormGroup = this.filesFormGroup;
        break;
      case 2:
        currentFormGroup = this.calendarFormGroup;
        break;
      default:
        break;
    }  
    if (currentFormGroup) {
      this.markFormGroupAsTouched(currentFormGroup);
      if (currentFormGroup.valid) {
        this.stepper.next();
      }
    }
  }
  
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }

  goToPreviousStep(): void {
    this.stepper.previous();
  }

  confirmApplyForm() : void {
    if (this.stepper.selectedIndex === this.stepper.steps.length - 1) {
      console.log('Formulaire confirmé !');
    }
  }

  saveApplyForm(): void {
    if (this.calendarFormGroup) {
      this.markFormGroupAsTouched(this.calendarFormGroup);
      if (!this.calendarFormGroup.valid) {
        return;
      }
    }
    if (this.infoFormGroup.valid && this.filesFormGroup.valid && this.calendarFormGroup.valid) {
      const formData = this.infoFormGroup.value as GoodApplyForm;
      formData.visitDate = this.convertToDateOnly(this.calendarFormGroup.controls?.['visitDate'].value);
      formData.visitHour = this.calendarFormGroup.controls?.['visitHour'].value;
      this.goodService.apply(this.authService.getUserObject().id, this.good.goodId, formData).subscribe(() => {
        this.matSnackBarService.successNotify();
        this.router.navigateByUrl(ApplicationRoute.GOODS.navigationPath);
      });
    }
  }

  convertToDateOnly(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 as getMonth is zero-based
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
}