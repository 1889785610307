import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoodCreationService } from '../../services/good-creation.service';
import { BuildingState } from '../../models/enums/building-state.enum';
import { AppConstants } from 'src/shared/constants/app.constants';

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss']
})
export class GeneralFormComponent {

  @Input()
  generalFormGroup!: FormGroup;
  minYear = AppConstants.GOOD_MIN_CONSTRUCTIONYEAR;
  maxYear = new Date().getFullYear();

  buildingStates = Object.keys(BuildingState).filter(state => isNaN(Number(state)));

  constructor(
    readonly goodCreationService: GoodCreationService,
  ) { }

  get formControls(){
    return this.generalFormGroup.controls;
  }

  fieldIsEmpty(formControlName: string): boolean {
    const control = this.generalFormGroup.get(formControlName);
    return control ? control.touched && control.value === '' : true;
  }

  fieldIsNotNumber(formControlName: string): boolean {
    const control = this.generalFormGroup.get(formControlName);
    return control ? control.touched && control.value === '' : true;
  }

  dateFilter = (date: Date | null): boolean => {
    if (!date) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date >= today;
  };
}
