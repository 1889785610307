<div class="slider-home">
    <h1 id="home-title"> {{'homepage.texts.connect-to-simplicity' | translate}}</h1>
    <div *ngIf="!isLoggedIn">
        <button id="rent-good-button">
            <mat-icon id="rent-good-icon">house</mat-icon>
            {{'homepage.buttons.rent-good' | translate}}
        </button>
        <button id="find-rental-button">
            <mat-icon id="find-rental-icon">search</mat-icon>
            {{'homepage.buttons.find-rental' | translate}}
        </button>
    </div>
</div>

<div id="about" class="row text-center m-0">
    <div class="col">
        <img src="assets/images/home/intro.png" alt="Intro image">
    </div>
    <div class="col row text-start">
        <h2 id="intro-title">{{'homepage.texts.intro-title' | translate }}</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aut consequatur, cupiditate deserunt dignissimos dolores ducimus et exercitationem facere illo itaque molestias odio placeat quo quod recusandae soluta suscipit vitae!
        </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium adipisci asperiores autem blanditiis commodi culpa, dolores ducimus eum impedit inventore labore laboriosam maiores molestiae necessitatibus perferendis quod repellat
            vero.
        </p>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci animi architecto error, et, expedita facere iste labore laborum magnam minima minus nihil possimus quod reiciendis suscipit tempore veniam voluptatem?
        </p>
    </div>
</div>

<div class="row info-about m-0">
    <h2>{{'homepage.texts.info' | translate }}</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi eligendi hic iure maxime nostrum nulla odit quisquam tempore! Debitis deleniti, fugiat inventore iure magni nihil perferendis provident quas quasi sunt!
    </p>
    <button id="know-more-button" routerLink="">{{'homepage.texts.know-more' | translate }}</button>
</div>

<div class="row validation text-primary  m-0">
    <div class="d-flex">
        <div class="d-flex search-text">
            <div class="box-images">
                <img src="../../assets/images/home/real-estate.png" alt="Glass">
            </div>
            <div>
                <h4>Lorem Ipsum</h4>
                <p>Consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam.
                </p>
            </div>

        </div>

        <div class="d-flex contract-text">
            <div class="box-images">
                <img src="../../assets/images/home/contract.png" alt="Contract">

            </div>
            <div>
                <h4>Lorem Ipsum</h4>
                <p> Consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam.
                </p>
            </div>
        </div>
    </div>

    <div class="d-flex">
        <div class="d-flex house-text">
            <div class="box-images">
                <img src="../../assets/images/home/house.png" alt="house">
            </div>
            <div>
                <h4>Lorem Ipsum</h4>
                <p> Consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam.
                </p>
            </div>
        </div>

        <div class="d-flex chat-text">
            <div class="box-images">
                <img src="../../assets/images/home/chat.png" alt="Chat">
            </div>
            <div>
                <h4> Lorem Ipsum </h4>
                <p> Consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="row see-more m-0">
    <div class="col fill">
        <img id="key-img" src="assets/images/home/key-home.png" alt="Lock">
    </div>
    <div class="col-6 know-more">
        <h2>LOREM IPSUM DOLOR SIT AMET</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci animi architecto error, et, expedita facere iste labore laborum magnam minima minus nihil possimus quod reiciendis suscipit tempore veniam voluptatem?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci animi architecto error, et, expedita facere iste labore laborum magnam minima minus nihil possimus quod reiciendis suscipit tempore veniam voluptatem?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci animi architecto error, et, expedita facere iste labore laborum magnam minima minus nihil possimus quod reiciendis suscipit tempore veniam voluptatem?</p>
        <button [routerLink]="ApplicationRoute.ABOUT_US.navigationPath" id="know-more">
            {{'homepage.texts.know-more' | translate }}<mat-icon id="arrow-icon">trending_flat</mat-icon>
        </button>
    </div>
</div>