import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserRoles } from 'src/shared/enums/user-roles.enum';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private selectedRoleSubject = new BehaviorSubject<string>(localStorage.getItem('selectedRole') || UserRoles.OWNER);
  selectedRole$ = this.selectedRoleSubject.asObservable();

  constructor() {}

  setSelectedRole(role: string): void {
    this.selectedRoleSubject.next(role);
  }
}