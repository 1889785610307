import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {Subject, takeUntil} from "rxjs";
import {FileExtension} from "../../../shared/enums/file-extension";
import {GlobalFileService} from "../../goods/services/global-file.service";

@Component({
  selector: 'app-upload-doc-file',
  templateUrl: './upload-doc-file.component.html',
  styleUrls: ['./upload-doc-file.component.scss']
})
export class UploadDocFileComponent implements OnInit, OnDestroy {

  private readonly ONE_MEGA_BIT: number = 1000000;

  @Input()
  maxFileSizeInMB = 1;

  @Output()
    uploadFile: EventEmitter<UploadFileModel> = new EventEmitter<UploadFileModel>();

  fileErrors: FileErrors | undefined = undefined;

  file: File | undefined = undefined;

  files: NgxFileDropEntry[] = [];

  filesNames: string[] = [];

  uploadedFiles: UploadedFile[] = [];

  @Input()
  fileExtensions: FileExtension[] = [
      FileExtension.DOC, FileExtension.DOCX, FileExtension.XLS,
      FileExtension.XLSX, FileExtension.PPT, FileExtension.PPTX, FileExtension.PDF
  ];

  @Input() title: string = "";

  @Input() controlName: string = "";

  @Input() maxFilesNumber: number = 1;

  @Input()
  uploadOnDrop = false;

  showInvalidFilesLengthErrorMessage = false;

  @Output()
  fileOnDelete = new EventEmitter<NgxFileDropEntry|UploadedFile>();

  selectedImagePreviews: string[] = [];


  @Output()
  fileOnDrop = new EventEmitter<NgxFileDropEntry[]>();

  subDestroyer$ = new Subject<void>();

  constructor(
    private readonly fileService: GlobalFileService,
    ) {}

  ngOnInit() {
    this.fileService.onUploaded
        .pipe(takeUntil(this.subDestroyer$))
        .subscribe(files => {
          this.uploadedFiles = files;
        });
  }

  dropped(files: NgxFileDropEntry[]): void {
    this.filesNames = [];
    if(this.maxFilesNumber <= 1) {
      this.files = [];
    }
    this.resetErrors();
    if (!this.isFilesLengthValid(files)) {
      this.showInvalidFilesLengthErrorMessage = true;
      return;
    }

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!this.isDocumentValid(file) || !file) {
            return;
          }          
          this.filesNames.push(file.name);
          this.uploadFile.emit({controleName: this.controlName, files: [droppedFile]});
          this.fileOnDrop.emit([droppedFile]);

          if(! this.uploadOnDrop) {
            this.files.push(droppedFile);
          }
        });
      }
    }    
  }

  delete(index: number): void {
    let file;

    if(this.uploadOnDrop) {
      file = this.uploadedFiles[index];
      this.uploadedFiles.splice(index, 1)
    } else {
      file = this.files[index];
      this.files.splice(index, 1);      
    }    
    this.fileOnDelete.emit(file);
  }

  private toMegaBit(fileSize: number): number {
    return fileSize / this.ONE_MEGA_BIT;
  }

  private isFilesLengthValid(files: NgxFileDropEntry[]): boolean{
    return files.length <= this.maxFilesNumber;
  }

  private isDocumentValid(file: File | null): boolean {
    this.fileErrors = {
      showInvalidFileErrorMessage: !file,
      showInvalidFileSizeErrorMessage: !!file && this.toMegaBit(file.size) > this.maxFileSizeInMB,
      showInvalidFileExtensionErrorMessage: !!file &&
        this.fileExtensions.filter((type: string) => file.name.indexOf(type) > -1).length === 0,
    };

    return Object.values(this.fileErrors).filter((error: boolean) => error).length === 0;
  }

  private resetErrors(): void {
    this.showInvalidFilesLengthErrorMessage = false;
    this.fileErrors = undefined;
  }

  public fileOver(files: NgxFileDropEntry[]): void {
    console.log(event);
  }

  public fileLeave(event: any): void {
    console.log(event);
  }

  get allowedMultipleFiles(): boolean {
    return this.maxFilesNumber > 1;
  }

  protected readonly NgxFileDropEntry = NgxFileDropEntry;

  ngOnDestroy(): void {
    this.subDestroyer$.next();
    this.subDestroyer$.complete();
  }
}

interface FileErrors {
  showInvalidFileErrorMessage: boolean;
  showInvalidFileSizeErrorMessage: boolean;
  showInvalidFileExtensionErrorMessage: boolean;
}

interface UploadFileModel {
  controleName: string;
  files: NgxFileDropEntry[];
}

export interface UploadedFile {
  id: number,
  name: string,
  url: string,
  sizeInKB: number,
  contentType: string
}
