<div id="item" class="row w-100 m-0 py-3 align-items-center">
    <div class="col-md-1 p-0" id="logo">
        <a [routerLink]="ApplicationRoute.HOME.navigationPath">
            <img src="../../assets/images/logo.png">
        </a>
    </div>
    <app-header-menu [isOwnerMode]="isOwnerMode" class="{{ isLoggedIn ? 'col-md-2' : 'col-md-5' }}" [isLoggedIn]="isLoggedIn ? true : false" [hasGoods]="hasGoods"></app-header-menu>

    <div class="col-md-3 p-0" *ngIf="!isLoggedIn">
        <button mat-button (click)="login()" id="login-button">{{'menu.login' | translate}}</button>
        <button mat-button [routerLink]="ApplicationRoute.PROFILE.navigationPath" id="register-button" type="submit">{{'menu.register' | translate}}</button>
    </div>

    <div class="col-md-6 d-flex align-items-center" *ngIf="isLoggedIn">
        <mat-slide-toggle id="role-switcher" (change)="toggle()" [(ngModel)]="isOwnerMode">
            <p class="user-roles" *ngIf="isOwnerMode">{{'menu.roles.owner' | translate}}</p>
            <p class="user-roles" *ngIf="!isOwnerMode">{{'menu.roles.occupant' | translate}}</p>
        </mat-slide-toggle>


        <button mat-button id="add-good-button" *ngIf="isOwnerMode" [routerLink]="ApplicationRoute.GOODS.navigationPath + ApplicationRoute.CREATE.navigationPath">
            <mat-icon id="home-icon">home</mat-icon>
            {{ 'menu.add-good-item' | translate }}
        </button>
        <button mat-button id="search-button" *ngIf="!isOwnerMode" [routerLink]="ApplicationRoute.GOODS.navigationPath">
            <mat-icon id="search-icon">search</mat-icon>
            {{'menu.rent-item' | translate}}
        </button>
        <button mat-button [matMenuTriggerFor]="menu" id="account-button" title="{{'menu.welcome' | translate: {'fullName' : capitalizeFullName(fullName)} }}">
            {{formatFullName(fullName)}}
            <mat-icon id="account-icon">expand_more</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="isOwnerMode" id="my-goods-button" [routerLink]="ApplicationRoute.OWNERS_ROUTE.navigationPath + ApplicationRoute.MY_GOODS.navigationPath">
                {{ 'menu.my-goods-item' | translate}}</button>
            <button mat-menu-item routerLink="appointment" *ngIf="isOwnerMode" [routerLink]="ApplicationRoute.MY_OPPOINTMENTS_ROUTE.navigationPath">
                {{'menu.appointment-item' | translate}}</button>
            <button mat-menu-item routerLink="good" *ngIf="!isOwnerMode">{{ 'menu.my-good-item' | translate }}</button>
            <button mat-menu-item [routerLink]="ApplicationRoute.MY_APPLICATION_ROUTE.navigationPath" *ngIf="!isOwnerMode">{{ 'menu.my-application-item' | translate }}</button>
            <button mat-menu-item *ngIf="!isOwnerMode" [routerLink]="ApplicationRoute.MY_FAVORITES.navigationPathWithParam('?onlyFavorite=true')">
                {{ 'menu.my-interests-item' | translate}}
            </button>
            <button mat-menu-item *ngIf="!isLoggedIn" [routerLink]="ApplicationRoute.GOODS.navigationPath">{{
                'menu.rent-item' | translate }}</button>
            <button mat-menu-item *ngIf="isLoggedIn" routerLink="messages">
                {{ 'menu.messages-item' | translate}}
            </button>
            <button mat-menu-item *ngIf="isLoggedIn" [routerLink]="ApplicationRoute.PROFILE.navigationPath">{{'menu.profile-item' | translate}}</button>
            <button mat-menu-item *ngIf="isLoggedIn" (click)="logout()">{{'menu.logout-item' | translate}}</button>
        </mat-menu>

    </div>
    <app-header-language class="col-md-1"></app-header-language>
</div>