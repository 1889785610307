<div id="content">
    <mat-stepper>
        <mat-step [stepControl]="infoFormGroup">
            <ng-template matStepLabel>{{'goods.apply-form.stepper.info-step' | translate}}</ng-template>
            <app-occupant-info-form [infoFormGroup]="infoFormGroup"></app-occupant-info-form>
        </mat-step>
        <mat-step [stepControl]="filesFormGroup">
            <ng-template matStepLabel>{{'goods.apply-form.stepper.files-step' | translate}}</ng-template>
            <app-occupant-files-form [filesFormGroup]="filesFormGroup" [good]="good" [tempApplyFormId]="tempApplyFormId"></app-occupant-files-form>
        </mat-step>
        <mat-step [stepControl]="calendarFormGroup">
            <ng-template matStepLabel>{{'goods.apply-form.stepper.calendar-step' | translate}}</ng-template>
            <app-occupant-calendar-form *ngIf="good" [goodId]="good.goodId" [calendarFormGroup]="calendarFormGroup"></app-occupant-calendar-form>
        </mat-step>
    </mat-stepper>

    <div *ngIf="applyFormService.stepper">
        <button mat-button (click)="goToPreviousStep()" id="back-button" *ngIf="applyFormService.stepper.selectedIndex > 0">
            {{'goods.apply-form.stepper.previous-button' | translate}}
        </button>
        <button mat-button (click)="goToNextStep()" id="next-button" *ngIf="applyFormService.stepper.selectedIndex < applyFormService.stepper.steps.length - 1">
            {{'goods.apply-form.stepper.next-button' | translate}}
        </button>
        <button mat-button (click)="saveApplyForm()" id="confirm-button" *ngIf="applyFormService.stepper.selectedIndex === applyFormService.stepper.steps.length - 1">
            {{'goods.apply-form.stepper.confirm-button' | translate}}
        </button>
    </div>

</div>