<div class="d-flex">
    <div class="d-block left-menu">
        <button [routerLink]="ApplicationRoute.GOODS.navigationPath"> {{ 'footer-menu.search-item' | translate }} </button> <br>
        <button [routerLink]="ApplicationRoute.ABOUT_US.navigationPath"> {{ 'footer-menu.about-item' | translate }} </button> <br>
        <button [routerLink]="ApplicationRoute.CONTACT.navigationPath"> {{ 'footer-menu.contact-item' | translate }} </button> <br>
    </div>

    <div class="d-block right-menu">
        <button routerLink="legal-mentions"> {{ 'footer-menu.mentions-item' | translate }} </button> <br>
        <button routerLink="newsletter"> {{ 'footer-menu.newsletter-item' | translate }} </button>
    </div>
</div>