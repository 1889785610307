import {Component} from '@angular/core';
import { ApplicationRoute } from 'src/shared/constants/application-route';


@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent {

  ApplicationRoute = ApplicationRoute;
}
