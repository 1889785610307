<form [formGroup]="installationsFormGroup">
    <h2>{{'property-adding.installations' | translate}}</h2>
    <mat-label for="hasHandicapAccess">
        {{'property-adding.handicap' | translate}}
        <mat-icon id="prm-icon"  matTooltip="{{'property-adding.prm' | translate}}">info</mat-icon>
    </mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasHandicapAccess">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-label for="hasIntercom">{{'property-adding.intercom' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasIntercom">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-label for="hasTvCable">{{'property-adding.tv-cable' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasTvCable">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-label for="hasLift">{{'property-adding.lift' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="hasLift">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
</form>