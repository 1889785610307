import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Good } from '../../../../app/goods/models/good.model'
import { GoodService } from 'src/app/goods/services/good.service';
import { GoodSearch } from 'src/app/goods/models/good-search.model';
import { PropertyType } from 'src/app/goods/models/enums/property-type.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './good-search-filters.component.html',
  styleUrls: ['./good-search-filters.component.scss']
})
export class GoodSearchFiltersComponent {

  filters!: FormGroup;
  thumbLabel = true;
  energyClassesList: string[] = ['A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];
  goods!: Good[];
  filteredGoods: Good[] = [];
  isFilterApplied: boolean = false;



  PropertyType = PropertyType;

  constructor(
    private readonly fb: FormBuilder,
    private readonly goodService: GoodService,
    private readonly dialogRef: MatDialogRef<GoodSearchFiltersComponent>
  ) { }

  ngOnInit(): void {
    this.filters = this.fb.group({
      postalCode: [''],
      propertyType: [null],
      minBedrooms: [''],
      maxBedrooms: [''],
      disponibility: [''],
      minLivingSpace: [''],
      maxLivingSpace: [''],
      exteriorElements: [''],
      minFacade: [''],
      maxFacade: [''],
      minParking: [''],
      energyClasses: ['']
    });

    this.goodService.getGoods().subscribe(
      (goods: Good[]) => {
        this.goods = goods;
      }
    );
  }

  get energyClasses(): AbstractControl<any, any> {
    return this.filters.controls['energyClasses'];
  }

  saveFilters(): void {
    const postalCode = this.filters.get('postalCode')?.value;
    const propertyType = this.filters.get('propertyType')?.value;
    const minBedrooms = this.filters.get('minBedrooms')?.value;
    const maxBedrooms = this.filters.get('maxBedrooms')?.value;
    const disponibility = this.filters.get('disponibility')?.value;
    const minLivingSpace = this.filters.get('minLivingSpace')?.value;
    const maxLivingSpace = this.filters.get('maxLivingSpace')?.value;
    const exteriorElements = this.filters.get('exteriorElements')?.value;
    const minFacade = this.filters.get('minFacade')?.value;
    const maxFacade = this.filters.get('maxFacade')?.value;
    const minParking = this.filters.get('minParking')?.value;
    const energyClass = this.filters.get('energyClasses')?.value;

    const searchParams: GoodSearch = {
      address: {
        postalCode,
        streetNb: '',
        streetName: '',
        city: '',
        countryIsocode: ''
      },
        propertyType,
        minBedrooms,
        maxBedrooms,
        disponibility,
        minLivingSpace,
        maxLivingSpace,
        exteriorElements,
        minFacade,
        maxFacade,
        minParking,
        energyClass
    }
    this.dialogRef.close(searchParams);
  }
}
