import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoodCreationService } from '../../services/good-creation.service';
import { KitchenType } from '../../models/enums/kitchen-type.enums';
import { AppConstants } from 'src/shared/constants/app.constants';

@Component({
  selector: 'app-interior-exterior-form',
  templateUrl: './interior-exterior-form.component.html',
  styleUrls: ['./interior-exterior-form.component.scss']
})
export class InteriorExteriorFormComponent {

  @Input()
  interiorExteriorFormGroup!: FormGroup;

  minParkingSpaces = AppConstants.GOOD_MIN_PARKINPLACES;
  maxParkingSpaces = AppConstants.GOOD_MAX_PARKINPLACES;
  minLandArea = AppConstants.GOOD_MIN_LANDAREA;
  maxLandArea = AppConstants.GOOD_MAX_LANDAREA;

  minLivingSpaceM2 = AppConstants.GOOD_MIN_LIVINGSPACEM2;
  maxLivingSpaceM2 = AppConstants.GOOD_MAX_LIVINGSPACEM2;
  minNbOfRooms = AppConstants.GOOD_MIN_NBOFROOMS;
  maxNbOfRooms = AppConstants.GOOD_MAX_NBOFROOMS;

  kitchenTypes = Object.keys(KitchenType).filter(type => isNaN(Number(type)));

  constructor(readonly goodCreationService: GoodCreationService) { }

  get formControls(){
    return this.interiorExteriorFormGroup.controls;
  }

  get hasGarden(): boolean {
    return this.interiorExteriorFormGroup.controls['hasGarden'].value;
  }

  get terrace(): boolean {
    return this.interiorExteriorFormGroup.controls['hasTerrace'].value;
  }

  fieldIsEmpty(formControlName: string): boolean {
    const control = this.interiorExteriorFormGroup.get(formControlName);
    return control ? control.touched && control.value === '' : true;
  }

}
