import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoodCreationService } from '../../services/good-creation.service';
import { EnergyClass } from '../../models/energy-class.enum';
import { HeatingType } from '../../models/heating-type.enum';
import { GlazingType } from '../../models/glazing-type.enum';

@Component({
  selector: 'app-energy-finance-form',
  templateUrl: './energy-finance-form.component.html',
  styleUrls: ['./energy-finance-form.component.scss']
})
export class EnergyFinanceFormComponent {


  @Input()
  energyFinanceFormGroup!: FormGroup;
  energyClasses = Object.keys(EnergyClass).filter(type => isNaN(Number(type)));
  heatingTypes = Object.keys(HeatingType).filter(type => isNaN(Number(type)));
  glazingTypes = Object.keys(GlazingType).filter(type => isNaN(Number(type)));
  rentalGuarantees = [0, 1, 2]

  constructor(readonly goodCreationService: GoodCreationService) { }

  fieldIsEmpty(formControlName: string): boolean {
    const control = this.energyFinanceFormGroup.get(formControlName);
    return control ? control.touched && control.value === '' : true;
  }

  get glazingType(): string {
    return this.energyFinanceFormGroup.controls['glazingType'].value;
  }
}
