<div class="custom-dialog-content">
    <button mat-button class="delete-button" (click)="closeDialog()">
        <mat-icon class="delete-icon">close</mat-icon>
    </button>
    <div class="container-fluid p-0 m-0">
        <div class="row m-0 mb-2">
            <div class="col-12 p-1">
                <h3>{{'review-popup.common.title' |translate}}</h3>
            </div>
            <div class="col-6 p-1"><strong>{{'review-popup.common.property-type' | translate}}</strong> {{ formData.common.propertyType }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.common.good-title' | translate}}</strong> {{ formData.common.goodTitle }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.common.street-name' | translate}}</strong> {{ formData.common.address.streetName }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.common.street-number' | translate}}</strong> {{ formData.common.address.streetNb }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.common.postal-code' | translate}}</strong> {{ formData.common.address.postalCode }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.common.city' | translate}}</strong> {{ formData.common.address.city }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.common.description' | translate}}</strong>
                <div class="description-container" [class.show]="show">
                    <div id="description">
                        {{ formData.common.description }}
                    </div>
                </div>
                <button id="description-show-more-button" (click)="show = !show">{{ (show ? 'good-details.show-less': 'good-details.show-more')
                    | translate }}</button> </div>
        </div>
        <div class="row m-0 mb-2">
            <div class="col-12 p-1">
                <h3>{{'review-popup.general.title' |translate}}</h3>
            </div>
            <div class="col-6 p-1"><strong>{{'review-popup.general.disponibility' | translate}}</strong> {{ formData.general.disponibility | date }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.general.construction-year' | translate}}</strong> {{ formData.general.constructionYear }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.general.building-state' | translate}}</strong> {{ formData.general.buildingState }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.general.facades-number' | translate}}</strong> {{ formData.general.facadesNumber }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.general.animals-allowed' | translate}}</strong> {{ formData.general.isAnimalAllowed }}</div>
        </div>
        <div class="row m-0 mb-2">
            <div class="col-12 p-1">
                <h3>{{'review-popup.interior-exterior.title' |translate}}</h3>
            </div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.living-space-M2' | translate}}</strong> {{ formData.interiorExterior.livingSpaceM2 }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.rooms-number' | translate}}</strong> {{ formData.interiorExterior.nbOfRooms }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.kitchen-type' | translate}}</strong> {{ formData.interiorExterior.kitchenType }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.attic' | translate}}</strong> {{ formData.interiorExterior.hasAttic }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.cellar' | translate}}</strong> {{ formData.interiorExterior.hasCellar }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.furnitures' | translate}}</strong> {{ formData.interiorExterior.hasFurnitures }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.land-area' | translate}}</strong> {{ formData.interiorExterior.landArea }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.garden' | translate}}</strong> {{ formData.interiorExterior.hasGarden }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.garden-area' | translate}}</strong> {{ formData.interiorExterior.gardenArea }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.terrace' | translate}}</strong> {{ formData.interiorExterior.hasTerrace }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.terrace-area' | translate}}</strong> {{ formData.interiorExterior.terraceArea }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.interior-exterior.parking-places' | translate}}</strong> {{ formData.interiorExterior.parkingPlaces }}</div>
        </div>
        <div class="row m-0 mb-2">
            <div class="col-12 p-1">
                <h3>{{'review-popup.installations.title' | translate}}</h3>
            </div>
            <div class="col-6 p-1"><strong>{{'review-popup.installations.handicap-access' | translate}}</strong> {{ formData.installations.hasHandicapAccess }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.installations.intercom' | translate}}</strong> {{ formData.installations.hasIntercom }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.installations.tv-cable' | translate}}</strong> {{ formData.installations.hasTvCable }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.installations.lift' | translate}}</strong> {{ formData.installations.hasLift }}</div>
        </div>
        <div class="row m-0 mb-2">
            <div class="col-12 p-1">
                <h3>{{'review-popup.energy-finance.title' | translate}}</h3>
            </div>
            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.energy-class' | translate}}</strong> {{ formData.energyFinance.energyClass }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.heating-type' | translate}}</strong> {{ formData.energyFinance.heatingType }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.glazing-type' | translate}}</strong> {{ formData.energyFinance.glazingType }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.other' | translate}}</strong> {{ formData.energyFinance.other }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.monthly-rent' | translate}}</strong> {{ formData.energyFinance.monthlyRent }}</div>
            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.monthly-rental-charges' | translate}}</strong> {{ formData.energyFinance.monthlyRentalCharges }}</div>

            <div class="col-6 p-1"><strong>{{'review-popup.energy-finance.rental-guarantee' | translate}}</strong> {{ formData.energyFinance.rentalGuarantee }}</div>
        </div>
        <div class="row m-0 mb-2">
            <div class="col-12 p-1">
                <h3>{{'review-popup.attachments.title' | translate}}</h3>
            </div>
            <div class="col-12 p-1"><strong>{{'review-popup.attachments.image-file' | translate}}</strong> {{ formData.attachments.imageFileIds }}</div>
        </div>
    </div>

</div>