import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, Subject, tap} from "rxjs";
import {BackendConstants} from "src/shared/constants/backend.constants";
import {GoodCreation} from "../models/good-creation.model";
import {Good} from "../models/good.model";
import {GoodFullInfos} from "../models/good-full-infos.model";
import {GoodApplyForm} from "../models/good-apply-form.model";
import {GoodSearch} from "../models/good-search.model";
import {AuthService} from "src/app/services/auth.service";

@Injectable()
export class GoodService {

    static GOODS_ENDPOINT = "goods";
    static AVAILABLE_GOODS_ENDPOINT = "public/goods";
    static OWNERS_ENDPOINT = "owners";
    static CANDIDATES_ENDPOINT = "candidates";
    static GOOD_REQUESTS = "goodRequests";
    static MY_GOODS_ENDPOINT = "my-goods";

    ownerId = this.authService.getUserObject()?.id;

    private currentGood$ = new Subject<Good>();
    currentGood = this.currentGood$.asObservable();


    constructor(private http: HttpClient,
        private readonly authService: AuthService) {}

    createGood(goodCreation: GoodCreation): Observable<Good> {
        return this.http.post<Good>(`${BackendConstants.BACKEND_URL}/${GoodService.OWNERS_ENDPOINT}/${this.ownerId}/${GoodService.GOODS_ENDPOINT}`, goodCreation);
    }

    editGood(id: number, goodCreation: GoodCreation): Observable<void> {
        return this.http.put<void>(`${BackendConstants.BACKEND_URL}/${GoodService.OWNERS_ENDPOINT}/${this.ownerId}/${GoodService.GOODS_ENDPOINT}/${id}`, goodCreation);
    }

    getGoods(): Observable<Good[]> {
        return this.http.get<Good[]>(`${BackendConstants.BACKEND_URL}/${GoodService.AVAILABLE_GOODS_ENDPOINT}`);
    }

    getCandidateGoods(candidateId: number): Observable<Good[]> {
        return this.http.get<Good[]>(`${BackendConstants.BACKEND_URL}/${GoodService.CANDIDATES_ENDPOINT}/${candidateId}/${GoodService.GOOD_REQUESTS}`);
    }

    getGoodFullInfos(id: number): Observable<GoodFullInfos> {
        return this.http.get<GoodFullInfos>(`${BackendConstants.BACKEND_URL}/${GoodService.AVAILABLE_GOODS_ENDPOINT}/${id}`);
    }


    getGood(id: number): Observable<Good> {
        return this.http.get<Good>(`${BackendConstants.BACKEND_URL}/${GoodService.AVAILABLE_GOODS_ENDPOINT}/${id}`)
            .pipe(tap(good => this.currentGood$.next(good)));
    }


    apply(candidateId: number, goodId: number, goodApplyForm: GoodApplyForm): Observable<void> {
        return this.http.post<void>(
            `${BackendConstants.BACKEND_URL}/${GoodService.CANDIDATES_ENDPOINT}/${candidateId}/${GoodService.GOODS_ENDPOINT}/${goodId}/apply`,
            goodApplyForm);
    }

    getFilteredGoods(goodSearch: GoodSearch): Observable<Good[]> {
        const params = {
            postalCode: goodSearch.address.postalCode || null,
            propertyType: goodSearch.propertyType || null,
            minBedrooms: goodSearch.minBedrooms || null,
            maxBedrooms: goodSearch.maxBedrooms || null,
            disponibility: goodSearch.disponibility || null,
            minLivingSpace: goodSearch.minLivingSpace || null,
            maxLivingSpace: goodSearch.maxLivingSpace || null,
            exteriorElements: goodSearch.exteriorElements || null,
            minFacade: goodSearch.minFacade || null,
            maxFacade: goodSearch.maxFacade || null,
            minParking: goodSearch.minParking || null,
            energyToppings: goodSearch.energyClass || null
        };

        return this.http.post<Good[]>(`${BackendConstants.BACKEND_URL}/${GoodService.AVAILABLE_GOODS_ENDPOINT}/search`, params);
    }
}