import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {CreateGoodComponent} from '../create-good/create-good.component';
import {ReactiveFormsModule} from "@angular/forms";
import {CreateGoodRoutingModule} from './create-good-routing.module';
import {NavigationMenuModule} from 'src/shared/modules/navigation-menu/navigation-menu.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {GoodService} from '../services/good.service';
import {MatRadioModule} from "@angular/material/radio";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatPseudoCheckboxModule} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper'
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CommonFormComponent} from './common-form/common-form.component';
import {GeneralFormComponent} from '../create-good/general-form/general-form.component';
import {InteriorExteriorFormComponent} from '../create-good/interior-exterior-form/interior-exterior-form.component';
import {InstallationsFormComponent} from '../create-good/installations-form/installations-form.component';
import {EnergyFinanceFormComponent} from '../create-good/energy-finance-form/energy-finance-form.component';
import {DoneFormComponent} from '../create-good/done-form/done-form.component';
import {BACKEND_FORMATS} from 'src/shared/constants/backend-formats.constants';
import {AttachmentsFormComponent} from "./attachments-form/attachments-form.component";
import {NgxFileDropModule} from "ngx-file-drop";
import {ApplyFormCreateModule} from "../apply-form-create/apply-form-create.module";
import {MatTooltipModule} from '@angular/material/tooltip';
import { ReviewPopupComponent } from './review-popup/review-popup.component';
import { CreationValidationPopupComponent } from './creation-validation-popup/creation-validation-popup.component';
import { GoodScheduleService } from './schedule/service/good-schedule.service';



@NgModule({
  declarations: [
    CreateGoodComponent,
    CommonFormComponent,
    GeneralFormComponent,
    InteriorExteriorFormComponent,
    InstallationsFormComponent,
    EnergyFinanceFormComponent,
    DoneFormComponent,
    AttachmentsFormComponent,
    ReviewPopupComponent,
    CreationValidationPopupComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CreateGoodRoutingModule,
    NavigationMenuModule,
    TranslateModule.forChild(),
    MatSelectModule,
    MatInputModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSnackBarModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxFileDropModule,
    ApplyFormCreateModule,
    MatTooltipModule
  ],
  providers: [
    provideNgxMask(),
    CurrencyPipe,
    GoodService,
    GoodScheduleService,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-BE'},
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: BACKEND_FORMATS }
  ]
})
export class CreateGoodModule { }
