import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import {User} from "../models/user.model";

const USER_KEY = 'auth-user';
const USER_ROLES_KEY = 'auth-user-roles';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static getUserObject() {
      throw new Error("Method not implemented.");
  }

  public isLoggedIn: boolean = false;
  public roles: string[] | null = null;
  public userProfile: KeycloakProfile | null = null;
    
  constructor(private readonly keycloak: KeycloakService) {}

  public getIsLoggedIn(){
    return this.keycloak.isLoggedIn();
  }

  public login() {
    this.clearSessions();
    this.keycloak.login().then(() => console.log("The account logged in"));
  }

  public logout() {
    this.clearSessions();
    this.keycloak.logout().then(() => console.log("The account logged out"));
  }

  public saveUser(user: User) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public saveUserRoles(userRoles: string[]) {
    window.sessionStorage.removeItem(USER_ROLES_KEY);
    window.sessionStorage.setItem(USER_ROLES_KEY, JSON.stringify(userRoles));
  }

  public getUser(): any {
    if(!this.getIsLoggedIn()){
      return null;
    }
    return sessionStorage.getItem(USER_KEY);
  }

  public getUserObject(): User {
    return JSON.parse(this.getUser());
  }
  public getUserRoles(): any {
    if(!this.getIsLoggedIn()){
      return null;
    }
    return sessionStorage.getItem(USER_ROLES_KEY);
  }

  private clearSessions(){
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(USER_ROLES_KEY);
  }
}