<form [formGroup]="commonFormGroup">
    <h1>{{ 'property-adding.property-adding' | translate }}</h1>
    <mat-form-field class="form-group d-flex">
        <mat-label for="propertyType">{{'property-adding.property-type' | translate}}</mat-label>
        <mat-select class="typeSelector" formControlName="propertyType" required>
            <mat-option *ngFor="let type of PropertyType | keyvalue" [value]="type.value">{{ 'option.' + type.key | translate}}</mat-option>
        </mat-select>
        <div *ngIf="fieldIsEmpty('propertyType')" class="errors">
            {{'errors.property-type-field' | translate}}
        </div>
    </mat-form-field>
    <mat-form-field class="form-group d-flex">
        <mat-label for="goodTitle">{{'property-adding.good-title' | translate}}</mat-label>
        <input matInput formControlName="goodTitle" id="goodTitle" type="text">
        <div *ngIf="fieldIsEmpty('goodTitle')" class="errors">
            {{'errors.good-title-field' | translate}}
        </div>
    </mat-form-field>

    <h2>{{'property-adding.address-title' | translate }}</h2>
    <ng-container formGroupName="address">
        <mat-form-field class="form-group d-flex">
            <mat-label for="street">{{'property-adding.street' | translate}}</mat-label>
            <input matInput formControlName="streetName" id="street" type="text">
            <div *ngIf="fieldIsEmpty('address.streetName')" class="errors">
                {{'errors.street-name-field' | translate}}
            </div>
        </mat-form-field>


        <div class="d-flex col">
            <mat-form-field class="form-group number-field col-4">
                <mat-label for="number">{{'property-adding.number' | translate}}</mat-label>
                <input matInput formControlName="streetNb" id="number" type="text">
                <div *ngIf="fieldIsEmpty('address.streetNb')" class="errors">
                    {{'errors.street-number-field' | translate}}
                </div>
            </mat-form-field>
            <mat-form-field class="form-group postalcode-field col-4">
                <mat-label for="postalCode">{{'property-adding.postal-code' | translate}}</mat-label>
                <input matInput formControlName="postalCode" id="postalCode" type="text">
                <div *ngIf="isIncorrect()" class="errors">
                    {{'errors.postal-code-format' | translate}}
                </div>
                <div *ngIf="fieldIsEmpty('address.postalCode')" class="errors">
                    {{'errors.postal-code-field' | translate}}
                </div>
            </mat-form-field>


            <mat-form-field class="form-group city-field col-4">
                <mat-label for="city">{{'property-adding.city' | translate}}</mat-label>
                <input matInput formControlName="city" id="city" type="text" pattern="[a-zA-Z ]*">
                <div *ngIf="commonFormGroup.get('address.city')?.hasError('required') && commonFormGroup.get('address.city')?.touched" class="errors">
                    {{'error-message.required' | translate}}
                </div>
                <div *ngIf="!commonFormGroup.get('address.city')?.hasError('required') && commonFormGroup.get('address.city')?.hasError('pattern')" class="errors">
                    {{'error-message.letter-only' | translate}}
                </div>
            </mat-form-field>
        </div>
    </ng-container>

    <h2>{{'property-adding.description' | translate}}</h2>
    <mat-form-field class="form-group d-flex">
        <mat-label for="description">{{'property-adding.description' | translate}}</mat-label>
        <textarea matInput rows="5" formControlName="description" id="description" type="text"></textarea>
        <div *ngIf="fieldIsEmpty('description')" class="errors">
            {{'errors.description-field' | translate}}
        </div>
        <div *ngIf="descriptionLengthIsNotValid()" id="lenght-message" class="errors">
            {{'errors.description-length' | translate}}
        </div>
    </mat-form-field>


</form>