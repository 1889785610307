<div id="content">
    <h2> {{'about.about-us' | translate }} </h2>

    <img src="../../assets/images/home/key.png">
    
    <h4> {{'about.first-title' | translate}} </h4>
    
    <div>
        <p>
            Haec igitur Epicuri non probo, inquam. De cetero vellem equidem aut ipse doctrinis fuisset instructior est enim, 
            quod tibi ita videri necesse est, non satis politus iis artibus, quas qui tenent, eruditi appellantur aut ne 
            deterruisset alios a studiis. quamquam te quidem video minime esse deterritum.
        </p>
        
        <p>
            Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. 
            Quid? si ipsas inimicitias, depono rei publicae causa, quis me tandem iure reprehendet, praesertim 
            cum ego omnium meorum consiliorum atque factorum exempla semper ex summorum hominum consiliis atque 
            factis mihi censuerim petenda.
        </p>
    </div>
    
    <h4> {{'about.second-title' | translate }} </h4>
    
    <div>
        <p>
            Haec igitur Epicuri non probo, inquam. De cetero vellem equidem aut ipse doctrinis fuisset instructior est enim, 
            quod tibi ita videri necesse est, non satis politus iis artibus, quas qui tenent, eruditi appellantur aut ne 
            deterruisset alios a studiis. quamquam te quidem video minime esse deterritum.
        </p>
        
        <p>
            Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. 
            Quid? si ipsas inimicitias, depono rei publicae causa, quis me tandem iure reprehendet, praesertim 
            cum ego omnium meorum consiliorum atque factorum exempla semper ex summorum hominum consiliis atque 
            factis mihi censuerim petenda.
        </p>
    
        <p>
            Haec igitur Epicuri non probo, inquam. De cetero vellem equidem aut ipse doctrinis fuisset instructior est enim, 
            quod tibi ita videri necesse est, non satis politus iis artibus, quas qui tenent, eruditi appellantur aut ne 
            deterruisset alios a studiis. quamquam te quidem video minime esse deterritum.
        </p>
        
        <p>
            Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. 
            Quid? si ipsas inimicitias, depono rei publicae causa, quis me tandem iure reprehendet, praesertim 
            cum ego omnium meorum consiliorum atque factorum exempla semper ex summorum hominum consiliis atque 
            factis mihi censuerim petenda.
        </p>
    </div>
</div>

