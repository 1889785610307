<div class="form-group mb-3">
    <label>{{ title | translate }}* <small>({{maxFilesNumber + ' ' + 'max'}})</small> :</label>
    <div class="row upload-table p-1">
        <div class="col-md-6 px-0">
            <ngx-file-drop #uploadFileInput dropZoneLabel="{{ 'goods.apply-form.drop_file_here' | translate }}" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [multiple]="allowedMultipleFiles">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <button type="button" class="btn btn-upload" (click)="openFileSelector()">{{ "goods.apply-form.browse_files" | translate }}</button>
                </ng-template>
            </ngx-file-drop>
        </div>
        <div class="col-6">
            <div class="upload-table">
                <p class="mb-0 mb-2 px-1 uploaded-files-title">{{ "goods.apply-form.uploaded_files" | translate }}<small>({{ uploadOnDrop ? uploadedFiles.length : files.length }})</small>:
                    <span class="d-block" *ngFor="let fileName of filesNames">
                        {{ fileName }}
                    </span>
                    <small class="text-secondary d-block mt-1">{{'uploadDocumentForm.extensions' | translate: {fileExtensions: fileExtensions} }}</small>
                    <small class="text-secondary d-block">{{'uploadDocumentForm.maxFileSize' | translate: {fileSize: maxFileSizeInMB} }}</small>
                </p>
                <div class="d-flex flex-column gap-2">
                    <mat-error *ngIf="showInvalidFilesLengthErrorMessage">
                        {{'uploadDocumentForm.invalidFileLengthError' | translate: {maxFilesNumber: maxFilesNumber} }}.
                    </mat-error>
                    <mat-error *ngIf="fileErrors?.showInvalidFileErrorMessage">
                        {{'uploadDocumentForm.invalidFileError' | translate}}.
                    </mat-error>
                    <mat-error *ngIf="fileErrors?.showInvalidFileSizeErrorMessage">
                        {{'uploadDocumentForm.invalidFileSizeError' | translate: {fileSize: maxFileSizeInMB} }}.
                    </mat-error>
                    <mat-error *ngIf="fileErrors?.showInvalidFileExtensionErrorMessage">
                        {{'uploadDocumentForm.invalidFileTypeError' | translate}}.
                    </mat-error>
                </div>
            </div>
        </div>
    </div>
</div>