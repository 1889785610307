<ng-container *ngIf="uploadOnGoing">
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <p class="text-warning mb-0">{{'property-adding.loading-message' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</ng-container>
<app-upload-doc-file [fileExtensions]="acceptedFileExtensions" [maxFilesNumber]="maxNumberOfFileUploads"
    [maxFileSizeInMB]="maxFileSizeInMb" [title]="'property-adding.attachments' | translate" [uploadOnDrop]="true"
    (fileOnDrop)="uploadFile($event)" (fileOnDelete)="deleteFile($event)">
</app-upload-doc-file>

<h2>{{'property-adding.main-image-select' | translate}}</h2>

<div class="image-preview-container" *ngIf="selectedImagePreviews.length > 0">
    <div *ngFor="let preview of selectedImagePreviews; let i = index" class="image-preview">
        <label class="image-label" [for]="'main-image-checkbox-' + i">
            <input type="checkbox" [checked]="selectedMainImageIndex === i" class="checkbox-input" [value]="i" id="main-image-checkbox-{{i}}" (change)="toggleMainImage(i)">
            <img [src]="preview" alt="Preview {{i}}" id="image-preview">
        </label>
        <button id="delete-button" (click)="deletePreview(i)">
            <mat-icon id="delete-icon">cancel</mat-icon>
        </button>
    </div>
</div>