import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-occupant-info-form',
  templateUrl: './occupant-info-form.component.html',
  styleUrls: ['./occupant-info-form.component.scss']
})
export class OccupantInfoFormComponent {


  @Input()
  infoFormGroup!: FormGroup;
  

  get formControls(){
    return this.infoFormGroup.controls;
  }
}
