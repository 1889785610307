import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KeycloakService} from 'keycloak-angular';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {UserService} from "./services/user.service";
import {User} from "./models/user.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  title = 'notrebail-front';

  public isLoggedIn = false;
  public fullName = '';
  public roles: string[] | null = null;
  public userProfile!: User;
  isOwnerMode = false;

  constructor(private translateService: TranslateService,
    private readonly keycloak: KeycloakService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router) {}

    public async ngOnInit(): Promise<void> {
      this.translateService.setDefaultLang('en');
      this.isLoggedIn = await this.keycloak.isLoggedIn();

      if (this.isLoggedIn) {
        await this.loadConnectedUser();
        this.fullName =  this.userProfile.firstName + " " + this.userProfile.lastName;
        this.isLoggedIn = true;
      }
  }

  private loadConnectedUser(): Promise<void> {
    return new Promise(resolve => {
      if( ! this.authService.getUser()) {
        this.userService.getConnectedUserProfile()
            .subscribe(user => {
              this.userProfile = user;
              this.authService.saveUser(user);
              this.roles = this.keycloak.getUserRoles(true);
              this.authService.saveUserRoles(this.roles);
              resolve();
            });
      } else {
        this.userProfile = this.authService.getUserObject();
        this.roles = this.authService.getUserRoles();
        resolve();
      }
    });
  }
}

