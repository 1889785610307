import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Schedule } from "../models/schedule.model";
import { BackendConstants } from "src/shared/constants/backend.constants";

@Injectable()
export class GoodScheduleService {

    static GOODS_ENDPOINT = "goods";
    static SCHEDULES_ENDPOINT = "schedule"

    constructor(
        private http: HttpClient,
        ) { }

        
    getSchedules(goodId: number): Observable<Schedule[]> {
        const schedulesUrl = `${BackendConstants.BACKEND_URL}/${GoodScheduleService.GOODS_ENDPOINT}/${goodId}/${GoodScheduleService.SCHEDULES_ENDPOINT}`;
        return this.http.get<Schedule[]>(schedulesUrl);
    }
}