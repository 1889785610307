import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoodCreationService } from '../../services/good-creation.service';

@Component({
  selector: 'app-installations-form',
  templateUrl: './installations-form.component.html',
  styleUrls: ['./installations-form.component.scss']
})
export class InstallationsFormComponent {

  @Input()
  installationsFormGroup!: FormGroup;

  constructor(private readonly fb: FormBuilder,
    readonly goodCreationService: GoodCreationService) { }
}
