import {Injectable} from "@angular/core";
import {GoodCreation} from "../models/good-creation.model";
import {MatStepper} from "@angular/material/stepper";
import {GoodService} from "./good.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBarConfig} from "@angular/material/snack-bar";
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {ApplicationRoute} from "../../../shared/constants/application-route";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CreationValidationPopupComponent} from "../create-good/creation-validation-popup/creation-validation-popup.component";
import {GoodStatus} from "../../../shared/enums/good-status.enum";
import { MatSnackBarService } from "src/app/services/matSnackBar.service";
import { Observable } from "rxjs";
import { Good } from "../models/good.model";
import { BackendConstants } from "src/shared/constants/backend.constants";
import { HttpClient } from "@angular/common/http";
import { DatePipe } from '@angular/common';

@Injectable()
export class GoodCreationService {
    private good_ = { goodStatus: GoodStatus.OFFLINE } as GoodCreation;
    apiUrl = BackendConstants.BACKEND_URL;
    private stepper_?: MatStepper;

    constructor(
        private readonly goodService: GoodService,
        private readonly translateService: TranslateService,
        private readonly matSnackBarService: MatSnackBarService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly http: HttpClient,
        private readonly datePipe :DatePipe,
    ) { }

    fillGoodAndGoToNextStep(): void {
        const activeStep = this.stepper_?.selectedIndex;
        const activeFormGroup = this.stepper_?.selected?.stepControl as FormGroup;

        if (activeFormGroup && activeFormGroup.valid) {
            Object.assign(this.good_, activeFormGroup.value);
            if(this.good_.disponibility){
                this.transformDate(this.good_.disponibility);
            }
            if (this.stepper_?.selected) {
                this.stepper_.selected.completed = true;
            } this.stepper_?.next();
        } else {
            if (activeStep !== undefined && activeFormGroup instanceof FormGroup) {
                activeFormGroup.markAllAsTouched();
            }
        }
    }

    resetCreation(): void {
        this.stepper_?.reset();
        this.good_ = {} as GoodCreation;
    }

    goToPreviousStep(): void {
        this.stepper_?.previous();
    }

    saveGood(): void {
        this.goodService.createGood(this.good_).subscribe((newGood) => {
            const newGoodId = newGood.goodId;
            const snackBarConfig: MatSnackBarConfig = {
                panelClass: 'custom-snackbar',
                duration: 2000
            };
            this.matSnackBarService.successNotify(this.translateService.instant("snackbar.success"), snackBarConfig);
            setTimeout(() => {
                this.router.navigateByUrl(`${ApplicationRoute.GOODS.navigationPath}/${newGoodId}`);

                const dialogConfig: MatDialogConfig = {
                    width: '80em',
                    disableClose: true,
                    data: {
                        goodId: newGoodId
                    }
                };

                this.dialog.open(CreationValidationPopupComponent, dialogConfig);
            }, 5000);
        });
    }

    set stepper(stepper: MatStepper | undefined) {
        this.stepper_ = stepper;
    }

    get stepper(): MatStepper | undefined {
        return this.stepper_;
    }

    saveSchedule(schedule: any, goodId: number) {
        const url = `${this.apiUrl}${ApplicationRoute.GOODS.navigationPath}/${goodId}${ApplicationRoute.SCHEDULE.navigationPath}`;
        return this.http.post(url, schedule);
    }


    updateGoodStatus(newStatus: GoodStatus, goodId : number): Observable<Good> {  
        const url = `${this.apiUrl}${ApplicationRoute.GOODS.navigationPath}/${goodId}`
        const updatedGood = { goodStatus: newStatus };
        return this.http.patch<Good>(url, updatedGood);
    }

    transformDate(date: any) {
        return this.datePipe.transform(date, 'dd-MM-yyyy'); 
    }
}