import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApplicationRoute } from 'src/shared/constants/application-route';
import { GoodScheduleService } from '../../create-good/schedule/service/good-schedule.service';
import { Schedule } from '../../create-good/schedule/models/schedule.model';

@Component({
  selector: 'app-occupant-calendar-form',
  templateUrl: './occupant-calendar-form.component.html',
  styleUrls: ['./occupant-calendar-form.component.scss']
})

export class OccupantCalendarFormComponent {

  @Input()
  calendarFormGroup!: FormGroup;

  get formControls(){
    return this.calendarFormGroup.controls;
  }

  @Input()
  goodId!: number;

  schedules!: Schedule[];
  ApplicationRoute = ApplicationRoute;
  availableHours: string[] = [];
  availableDays: string[] = [];
  dayToAvailableHours: { [day: string]: string[] } = {};
  dayNames = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

  constructor(private readonly goodScheduleService: GoodScheduleService) { 
    this.dateFilter = this.dateFilter.bind(this);
  }

  ngOnInit() {
    this.goodScheduleService.getSchedules(this.goodId).subscribe((schedules: Schedule[]) => {
      this.schedules = schedules;

      const dayToAvailableHours: { [day: string]: string[] } = {};
      this.schedules.forEach(schedule => {
        if (!dayToAvailableHours[schedule.dayOfWeek]) {
          dayToAvailableHours[schedule.dayOfWeek] = [];
        }
        this.populateAvailableHours(schedule, dayToAvailableHours);
      });
  
      this.dayToAvailableHours = dayToAvailableHours;
  
      const newSchedule = {
        dayOfWeek: '',
        startHour: '',
        endHour: '',
        nbOfMinutes: 0
      };

      const schedule: Schedule = {
        dayOfWeek: newSchedule.dayOfWeek,
        startHour: newSchedule.startHour,
        endHour: newSchedule.endHour,
        nbOfMinutes: newSchedule.nbOfMinutes
      }

      if (this.schedules.length > 0) {
        const firstSchedule = this.schedules[0];

        schedule.startHour = firstSchedule.startHour;
        schedule.endHour = firstSchedule.endHour;
        schedule.nbOfMinutes = firstSchedule.nbOfMinutes;

        let currentHour: string = schedule.startHour;
        while (currentHour <= schedule.endHour) {
          this.availableHours.push(currentHour);
          currentHour = this.addMinutes(currentHour, schedule.nbOfMinutes);
        }
      }
      this.availableDays = this.schedules.map(schedule => schedule.dayOfWeek);      
      this.calendarFormGroup.get('visitDate')?.valueChanges.subscribe((selectedDate: Date) => {
        this.updateAvailableHours(selectedDate);
      });    
    });
  }

  dateFilter(date: Date | null): boolean {
    if (!date || !this.availableDays) {
      return false;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
      return false;
    }
    const dayOfWeekNumber = date.getDay();
    const dayOfWeekName = this.dayNames[dayOfWeekNumber]; 
    return this.availableDays.includes(dayOfWeekName);
  }

  private addMinutes(time: string, minutes: number): string {
    const [hour, minute] = time.split(':').map(Number);
    const totalMinutes = hour * 60 + minute + minutes;
    const newHour = Math.floor(totalMinutes / 60);
    const newMinute = totalMinutes % 60;
    return `${this.padZero(newHour)}:${this.padZero(newMinute)}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  private populateAvailableHours(schedule: Schedule, dayToAvailableHours: { [day: string]: string[] }): void {
    let currentHour: string = schedule.startHour;
    while (currentHour <= schedule.endHour) {
      dayToAvailableHours[schedule.dayOfWeek].push(currentHour);
      currentHour = this.addMinutes(currentHour, schedule.nbOfMinutes);
    }
  }

  private updateAvailableHours(selectedDate: Date): void {
    if (!selectedDate) {
      this.availableHours = [];
      return;
    }
    const dayOfWeekName = this.dayNames[selectedDate.getDay()];
    this.availableHours = this.dayToAvailableHours[dayOfWeekName] || [];
  }
}
