import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ApplyFormFileTypeEnum } from '../../models/apply-form-file-type.enum';
import { ApplyFormUploadFileService } from '../../services/apply-form-upload-file.service';
import { HttpStatusCode } from '@angular/common/http';
import { Good } from '../../models/good.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-occupant-files-form',
  templateUrl: './occupant-files-form.component.html',
  styleUrls: ['./occupant-files-form.component.scss']
})
export class OccupantFilesFormComponent {

  @Input()
  filesFormGroup!: FormGroup;

  @Input()
  good!: Good;
  
  @Input()
  tempApplyFormId!: string;

  householdComposition_files: NgxFileDropEntry[] = [];
  salaryFiles_files: NgxFileDropEntry[] = [];
  proofOfPayment_files: NgxFileDropEntry[] = [];

  constructor(
    private readonly applyFormUploadFileService: ApplyFormUploadFileService,
    readonly authService: AuthService
  ) { }

  private _updateFilesInputErrors(): void {
    if (this.householdComposition_files.length == 0) {
      this.filesFormGroup.controls["householdComposition"].setErrors({ required: true });
    } else {
      this.filesFormGroup.controls["householdComposition"].setErrors(null);
    }
    if (this.salaryFiles_files.length == 0) {
      this.filesFormGroup.controls["salaryFiles"].setErrors({ required: true });
    } else {
      this.filesFormGroup.controls["salaryFiles"].setErrors(null);
    }
    if (this.proofOfPayment_files.length == 0) {
      this.filesFormGroup.controls["proofOfPayment"].setErrors({ required: true });
    } else {
      this.filesFormGroup.controls["proofOfPayment"].setErrors(null);
    }
  }

  getAndSetUploadedFilesInput(uploadFileModel: UploadFileModel | any): void {    
    let fileType = "";
    switch (uploadFileModel.controleName) {
      case "householdComposition":
        fileType = ApplyFormFileTypeEnum.HOUSE_HOLD_COMPOSITION;
        this.householdComposition_files = uploadFileModel.files;
        break;
      case "salaryFiles":
        this.salaryFiles_files = uploadFileModel.files;
        fileType = ApplyFormFileTypeEnum.SALARY;
        break;
      case "proofOfPayment":
        this.proofOfPayment_files = uploadFileModel.files;
        fileType = ApplyFormFileTypeEnum.PROOF_OF_PAYMENT;
        break;
    }
    this._updateFilesInputErrors();
    this._onFileUpload(uploadFileModel.files, fileType);
  }

  private _onFileUpload(files: NgxFileDropEntry[], fileType: string): void {    
    if (files[0].fileEntry.isFile) {
      const formData = new FormData();
      formData.append(fileType, files[0].relativePath);
      this.applyFormUploadFileService.uploadFileOfApplyForm(this.good.goodId, this.authService.getUserObject().id, fileType, formData).subscribe({
        next: (applyFormId) => {
            this.tempApplyFormId = applyFormId;
          },
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            //Snacbar notification or console error of upload failed
          }
        }
      });
    }
  }

  get formControls(){
    return this.filesFormGroup.controls;
  }
}

interface UploadFileModel {
  controleName: string;
  files: NgxFileDropEntry[];
}