import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationMenuComponent} from "./navigation-menu.component";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";


@NgModule({
  declarations: [
      NavigationMenuComponent
  ],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        TranslateModule.forChild(),
        RouterLink
    ],
  exports: [
      NavigationMenuComponent
  ]
})
export class NavigationMenuModule { }
