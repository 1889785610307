import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { NavigationMenuModule } from 'src/shared/modules/navigation-menu/navigation-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from "@angular/material/radio";
import { MatNativeDateModule, MatPseudoCheckboxModule } from '@angular/material/core';
import { ApplyFormCreateRoutingModule } from './apply-form-create-routing.module';
import { ApplyFormCreateComponent } from './apply-form-create.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadDocFileComponent } from 'src/app/_shared-components/upload-doc-file/upload-doc-file.component';
import { OccupantInfoFormComponent } from './occupant-info-form/occupant-info-form.component';
import { OccupantFilesFormComponent } from './occupant-files-form/occupant-files-form.component';
import { OccupantCalendarFormComponent } from './occupant-calendar-form/occupant-calendar-form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ApplyFormService } from './service/apply-form.service';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    ApplyFormCreateComponent,
    UploadDocFileComponent,
    OccupantInfoFormComponent,
    OccupantFilesFormComponent,
    OccupantCalendarFormComponent,
  ],
  exports: [
    ApplyFormCreateComponent,
    UploadDocFileComponent
  ],
  imports: [
    CommonModule,
    ApplyFormCreateRoutingModule,
    ReactiveFormsModule,
    NavigationMenuModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSnackBarModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatToolbarModule,
    MatProgressBarModule,
    NgxFileDropModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    ApplyFormService
  ]
})
export class ApplyFormCreateModule { }
