import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateGoodComponent } from '../create-good/create-good.component';
import { GoodCreationService } from '../services/good-creation.service';
import { GoodService } from '../services/good.service';


const routes: Routes = [
  {
    path: '',
    component: CreateGoodComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [GoodCreationService, GoodService]
})
export class CreateGoodRoutingModule { }
