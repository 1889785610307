<h2 mat-dialog-title>{{'goods.filters-form.dialog-title' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="filters" class="d-block" id="filters-form">
        <h4> {{ 'goods.filters-form.location' | translate }} </h4>
        <mat-label class="d-block"> {{ 'goods.filters-form.enter-location' | translate }} </mat-label>
        <mat-form-field>
            <input matInput formControlName="postalCode">
            <span matTextSuffix>
                <mat-icon id="expansion-postalcode-icon"> location_on</mat-icon>
            </span>
        </mat-form-field>

        <h4> {{'goods.filters-form.property-type' | translate}} </h4>
        <mat-label class="d-block">{{'goods.filters-form.property-type-label' | translate}}</mat-label>
        <mat-form-field>
            <mat-select formControlName="propertyType" multiple>
                <mat-option [value]="PropertyType.HOUSE">{{ 'homepage.home.house' | translate}} </mat-option>
                <mat-option [value]="PropertyType.APARTMENT">{{ 'homepage.home.apartment' | translate }} </mat-option>
            </mat-select>
        </mat-form-field>

        <h4> {{'goods.filters-form.bedrooms' | translate}}</h4>
        <mat-label class="d-block">{{'goods.filters-form.minimum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="minBedrooms">
        </mat-form-field>

        <mat-label class="d-block">{{'goods.filters-form.maximum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="maxBedrooms">
        </mat-form-field>

        <h4> {{ 'goods.filters-form.budget' | translate }} </h4>
        <mat-label class="d-block">{{'goods.filters-form.price-range' | translate}}</mat-label>
        <mat-slider [discrete]="thumbLabel" min="0" max="2500" step="10">
            <input value="0" matSliderStartThumb>
            <input value="2500" matSliderEndThumb>
        </mat-slider>

        <div>
            <h4 class="d-block"> {{ 'goods.filters-form.disponibility' | translate }}</h4>
            <mat-radio-group class="d-block" formControlName="disponibility">
                <mat-radio-button> {{ 'goods.filters-form.immediately' | translate }} </mat-radio-button>
                <mat-radio-button> {{ 'goods.filters-form.no-importance' | translate }} </mat-radio-button>
            </mat-radio-group>
        </div>

        <h4> {{'goods.filters-form.living-space' | translate}}</h4>
        <mat-label class="d-block">{{'goods.filters-form.minimum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="minLivingSpace">
        </mat-form-field>

        <mat-label class="d-block">{{'goods.filters-form.maximum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="maxLivingSpace">
        </mat-form-field>

        <div>
            <h4 class="d-block"> {{'goods.filters-form.exterior-elements' | translate }} </h4>
            <mat-selection-list class="d-block" formControlName="exteriorElements">
                <mat-list-option togglePosition="before"> {{ 'goods.filters-form.terrace' | translate }}
                </mat-list-option>
                <mat-list-option togglePosition="before"> {{ 'goods.filters-form.garden' | translate }}
                </mat-list-option>
            </mat-selection-list>
        </div>

        <h4>{{'goods.filters-form.facades' |translate}}</h4>
        <mat-label class="d-block">{{'goods.filters-form.minimum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="minFacade">
        </mat-form-field>

        <mat-label class="d-block">{{'goods.filters-form.maximum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="maxFacade">
        </mat-form-field>


        <h4>{{'goods.filters-form.parking' |translate}}</h4>
        <mat-label class="d-block">{{'goods.filters-form.minimum' | translate}}</mat-label>
        <mat-form-field>
            <input matInput type="text" formControlName="minParking">
        </mat-form-field>


        <h4> {{ 'goods.filters-form.energy' | translate }} </h4>
        <mat-label class="d-block">{{'goods.filters-form.energy-class' | translate}}</mat-label>
        <mat-select multiple formControlName="energyClasses" id="energy-selector">
            <mat-option *ngFor="let energyClass of energyClassesList" [value]="energyClass">{{energyClass}}</mat-option>
        </mat-select>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button id="confirm-dialog-button" (click)="saveFilters()">
        <mat-icon id="confirm-icon">check</mat-icon>
        {{'goods.filters-form.confirm-button' | translate}}
    </button>
    <button mat-button mat-dialog-close id="close-dialog-button">
        <mat-icon id="close-icon">close</mat-icon>
        {{'goods.filters-form.close-button' | translate}}
    </button>
</mat-dialog-actions>