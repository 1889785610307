export const environment = {
    production: false,
    backend_url: "https://test.notrebail.com/api",
    redirect_uri: "https://test.notrebail.com",
    recaptcha: {
        siteKey: '6LcwC7IUAAAAAJmhpWnP84XszJAE87zAytCJIzMC'
    },
    keycloak:{
        config:{
            url: 'https://auth.goinnovait.com',
            realm: 'notre-bail',
            clientId: 'notre-bail-api',
        }
    }
};