import { Injectable } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";

@Injectable()
export class ApplyFormService {

    private stepper_?: MatStepper;

    set stepper(stepper: MatStepper | undefined) {
        this.stepper_ = stepper;
    }

    get stepper(): MatStepper | undefined {
        return this.stepper_;
    }
}