import { Component, EventEmitter, Output, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GoodSearchFiltersComponent } from './good-search-filters/good-search-filters.component';
import { Router } from '@angular/router';
import {ApplicationRoute} from "../../constants/application-route";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoodSearch } from 'src/app/goods/models/good-search.model';
import { PropertyType } from 'src/app/goods/models/property-type.enum';


@Component({
  selector: 'app-goods-search',
  templateUrl: './goods-search.component.html',
  styleUrls: ['./goods-search.component.scss']
})
export class GoodsSearchComponent {

  @Output()
  filtersApplied = new EventEmitter<GoodSearch>();

  currentPageLink!: string;
  priceForm!: FormGroup;
  propertyType = new FormControl('');
  propertyTypesList: PropertyType[] = [PropertyType.HOUSE, PropertyType.APARTMENT];
  postalCode = new FormControl();
  selectedCodes: string[] = [];
  selectedValues: PropertyType[] = [];

  constructor(
    private dialog: MatDialog,
    private readonly router: Router,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.priceForm = this.fb.group({
      minPrice: [''],
      maxPrice: ['']
    });
  }

  filtersPopupOpen() {
    const dialogRef = this.dialog.open(GoodSearchFiltersComponent, {
      minWidth: '50%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filtersApplied.emit(result);
      }
    })
  }

  onClick() {
    if (this.router.url != ApplicationRoute.GOODS.navigationPath) {
      this.router.navigateByUrl(ApplicationRoute.GOODS.navigationPath)
    }
  }

  addCode() {
    const value = this.postalCode.value.trim();
    if (value && !this.selectedCodes.includes(value)) {
      this.selectedCodes.push(value);
      this.postalCode.setValue('');
    }
  }

  removeCodeChip(code: string) {
    const index = this.selectedCodes.indexOf(code);
    if (index >= 0) {
      this.selectedCodes.splice(index, 1);
    }
  }

  addType(event: any) {
    const value = event.option.value;
    if (!this.selectedValues.includes(value)) {
      this.selectedValues.push(value);
    }
  }

  removeTypeChip(value: string) {
    const index = this.selectedValues.findIndex((propertyType) => propertyType === value);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    }
  }
}