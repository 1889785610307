import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, retry, throwError, timer} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    static readonly RETRY_COUNT = 1;

    static readonly RETRY_DELAY_IN_MS = 700;

    static readonly RETRY_STATUSES = [504, 503];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            retry({count: ErrorInterceptor.RETRY_COUNT, delay: this.shouldRetry})
        );
    }

    // We can retry the request depending on the error status here
    private shouldRetry(err: HttpErrorResponse) {
        if(ErrorInterceptor.RETRY_STATUSES.includes(err.status)) {
            return timer(ErrorInterceptor.RETRY_DELAY_IN_MS);
        }
        return throwError(() => err);
    }
}