import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeModule } from './home/home.module';
import { ApplicationRoute } from '../shared/constants/application-route';
import { ErrorComponent } from "./error/error.component";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: ApplicationRoute.HOME.path,
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => HomeModule)
  },
  {
    path: ApplicationRoute.CANDIDATES.path,
    loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule)
  },
  {
    path: ApplicationRoute.GOODS.path,
    loadChildren: () => import('./goods/goods.module').then(m => m.GoodsModule)
  },
  {
    path: ApplicationRoute.ABOUT_US.path,
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: ApplicationRoute.CONTACT.path,
    loadChildren: () => import('./contacts/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: ApplicationRoute.PROFILE.path,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: ApplicationRoute.EDIT_PROFILE.path,
    loadChildren: () => import('./profile/edit-profile/edit-profile.module').then(m => m.EditProfileModule)
  },
  {
    path: ApplicationRoute.OWNERS_ROUTE.path + ApplicationRoute.MY_GOODS.navigationPath,
    loadChildren: () => import('./owner-goods/owner-goods.module').then(m => m.OwnerGoodsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ApplicationRoute.MY_OPPOINTMENTS_ROUTE.path,
    loadChildren: () => import('./owner-appointments/owner-appointments.module').then(m => m.OwnerAppointmentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ApplicationRoute.MY_APPLICATION_ROUTE.path,
    loadChildren: () => import('./my-applications/my-applications.module').then(m => m.MyApplicationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ApplicationRoute.PUBLIC.path + ApplicationRoute.GOODS.navigationPath + '?onlyFavorite=true',
    loadChildren: () => import('./favorites/favorites.module').then(m => m.FavoritesModule)
  },
  {
    path: ApplicationRoute.GOODS.path + '/:goodId' + ApplicationRoute.INVENTORIES_ROUTE.navigationPath,
    loadChildren: () => import('./inventories/inventories.module').then(m => m.InventoriesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ApplicationRoute.MESSAGES.path,
    loadChildren: () => import('./message/message.module').then(m => m.MessageModule)
  },
  {
    path: ApplicationRoute.LEGAL_MENTIONS.path,
    loadChildren: () => import('./legal-mentions/legal-mentions.module').then(m => m.LegalMentionsModule)
  },
  {
    path: ApplicationRoute.RGPD.path,
    loadChildren: () => import('./rgpd/rgpd.module').then(m => m.RGPDModule)
  },
  {
    path: ApplicationRoute.CGU.path,
    loadChildren: () => import('./cgu/cgu.module').then(m => m.CGUModule)
  },
  {
    path: ApplicationRoute.ERROR.path,
    component: ErrorComponent
  },
  {
    path: ApplicationRoute.UNKNOWN.path,
    redirectTo: ApplicationRoute.ERROR.path
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
