import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language} from "../../../shared/models/language.enum";
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-header-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  languages = Object.values(Language);

  @ViewChild('selector', { static: false }) selector!: MatSelect

  currentLanguage!: string;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    if (localStorage.getItem('locale') 
    && this.languages.map(language => language as string).includes(<string>localStorage.getItem('locale')!)) {
      this.translate.setDefaultLang(localStorage.getItem('locale')!);
      this.translate.use(localStorage.getItem('locale')!);
    }
    else if (this.translate.getBrowserLang() &&
     this.languages.map(language => language as string).includes(<string>this.translate.getBrowserLang())){
     this.translate.use(<string>this.translate.getBrowserLang());
    }
    else{
      this.translate.use(Language.ENGLISH);
    }
    this.currentLanguage = this.translate.currentLang;   
  }


  onSelectionChange(value: any) {
    this.translate.use(value);
    localStorage.setItem('locale', value);
    if(this.selector) {
      this.selector.close;
    }
  }
}
