export class AppConstants {

    static GOODS_MAX_FILE_UPLOADS = 8;

    static GOODS_MAX_FILE_SIZE_IN_MB = 2;

    static GOODS_IMG_PLACEHOLDER = "../../assets/images/immeuble.jpg";

    static USER_IMG_PLACEHOLDER = "../../assets/images/upload-lg-icon.png";

    static GOOD_MIN_NBOFROOMS = 0;

    static GOOD_MAX_NBOFROOMS = 10;

    static GOOD_MIN_PARKINPLACES = 0;

    static GOOD_MAX_PARKINPLACES = 10;

    static GOOD_MIN_LANDAREA = 0;// m²

    static GOOD_MAX_LANDAREA = 1000;// m²

    static GOOD_MIN_LIVINGSPACEM2 = 0;// m²

    static GOOD_MAX_LIVINGSPACEM2 = 1000;// m²

    static GOOD_MIN_CONSTRUCTIONYEAR = 1900;
}