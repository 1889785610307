import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandler, Injectable} from "@angular/core";
import {ErrorService} from "../services/error.service";
import {UserService} from "../services/user.service";
import {Router} from "@angular/router";
import {ApplicationRoute} from "../../shared/constants/application-route";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private readonly errorService: ErrorService,
        private readonly userService: UserService,
        private readonly router: Router
    ) {}
    handleError(error: Error | HttpErrorResponse): void {
        if(error instanceof HttpErrorResponse) {
            switch (error.status) {
                case 400:
                case 422:
                    this.errorService.showValidationErrors(error);
                    break;
                case 401:
                case 419:
                    this.userService.logout(); // return to login page
                    break;
                case 404:
                    this.router.navigateByUrl(ApplicationRoute.ERROR.navigationPath);
                    break;
                default:
                    this.errorService.showErrors(error);
            }
        } else {
            this.errorService.notify(error.message, error);
        }
    }
}