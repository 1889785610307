import {BackendConstants} from "../../../shared/constants/backend.constants";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {Injectable} from "@angular/core";
import {UploadedFile} from "../../_shared-components/upload-doc-file/upload-doc-file.component";

@Injectable({
    providedIn: "root"
})
export class GlobalFileService {

    onUploaded = new Subject<UploadedFile[]>();

    #apiBaseUrl = BackendConstants.BACKEND_URL;

    #attributeName = 'file';

    constructor(private readonly http: HttpClient) {}

    upload(file: File, isPublic = false): Observable<UploadedFile> {
        const formData = new FormData();
        formData.append(this.#attributeName, file, file.name);
        formData.append('is_public', isPublic.toString());
        return this.http.post<UploadedFile>(this.#apiBaseUrl + '/temp-files', formData);
    }

    delete(id: number) {
        this.http.delete(this.#apiBaseUrl + '/temp-files/' + id)
            .subscribe();
    }
}