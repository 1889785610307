import {Injectable, NgZone} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {TitleCasePipe} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class ErrorService {

    constructor(
        private readonly titlePipe: TitleCasePipe,
        private readonly snackbar: MatSnackBar,
        private readonly zone: NgZone,
        private readonly translator: TranslateService
    ) {}

    public showValidationErrors(err: HttpErrorResponse) {
        err.error.errors.forEach((error: ValidationError) => {
            this.notify(this.titlePipe.transform(error.attribute) + ' : ' + error.message, error);
        })
    }

    public showErrors(err: HttpErrorResponse) {
        err.error.errors.forEach((error: Error) => {
            this.notify(error.message, error);
        })
    }

    public notify(msg: string, error: Error) {
        this.zone.run(() => {
            this.snackbar.open(msg, this.translator.instant('snackbar.close'), {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['warn'],
                duration: 7000
            });
            throw error;
        });
    }
}

interface ValidationError extends Error {
    attribute: string;
}

interface Error {
    message: string
}