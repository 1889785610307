<form [formGroup]="generalFormGroup">
    <h2>{{'property-adding.general' | translate}}</h2>
    <div class="d-flex col">
        <mat-form-field appearance="fill" class="col-6">
            <mat-label for="disponibility">{{ 'property-adding.disponibility' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="disponibility" id="disponibility" [matDatepickerFilter]="dateFilter" />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel id="date-cancel-button">
                        {{ 'property-adding.date-button.cancel' | translate }}
                    </button>
                    <button mat-raised-button matDatepickerApply id="date-apply-button">
                        {{ 'property-adding.date-button.apply' | translate }}
                    </button>
                </mat-datepicker-actions>
            </mat-datepicker>
            <div *ngIf="fieldIsEmpty('disponibility')" class="errors">
                {{ 'errors.disponibility-field' | translate }}
            </div>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label for="constructionYear">{{ 'property-adding.construction-year' | translate }}</mat-label>
            <input matInput formControlName="constructionYear" id="constructionYear" type="number">
            <div *ngIf="formControls['constructionYear'].hasError('pattern') || formControls['constructionYear'].hasError('invalidYear')" class="errors">
                {{ 'errors.invalid-year-pattern' | translate :{minYear: minYear, maxYear: maxYear} }}
            </div>
        </mat-form-field>
    </div>
    <div class="d-flex col">
        <mat-form-field appearance="fill" class="col-6">
            <mat-label for="buildingState">{{'property-adding.building-state' | translate}}</mat-label>
            <mat-select formControlName="buildingState">
                <mat-option *ngFor="let state of buildingStates" [value]="state">
                    {{'option.' + state | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label for="facadesNumber">{{ 'property-adding.facades-number' | translate }}</mat-label>
            <input matInput formControlName="facadesNumber" id="facadesNumber" type="number">
            <div *ngIf="fieldIsEmpty('facadesNumber')" class="errors">
                {{ 'errors.facades-number-field' | translate }}
            </div>
            <div *ngIf="formControls['facadesNumber'].touched && formControls['facadesNumber'].hasError('pattern')" class="errors">
                {{ 'errors.field-invalid' | translate }}
            </div>
        </mat-form-field>
    </div>
    <mat-label for="isAnimalAllowed">{{'property-adding.animals' | translate}}</mat-label>
    <mat-radio-group class="form-group d-block" formControlName="isAnimalAllowed">
        <mat-radio-button value="true" color="primary">{{ 'option.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false" color="primary" [checked]="true">{{ 'option.no' | translate }}
        </mat-radio-button>
    </mat-radio-group>
</form>