import {AuthService} from '../services/auth.service';
import {ApplicationRoute} from 'src/shared/constants/application-route';
import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BackendConstants } from 'src/shared/constants/backend.constants';
import { TranslateService } from '@ngx-translate/core';
import { UserRoleService } from '../services/userRole.service';
import { UserRoles } from 'src/shared/enums/user-roles.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  ApplicationRoute = ApplicationRoute;
  isOwnerMode: boolean = false;

  @Output() 
  isOwnerModeChange = new EventEmitter<boolean>();
  apiUrl = BackendConstants.BACKEND_URL;
  hasGoods: boolean = false;

  @Input('header-isLoggedIn') isLoggedIn: boolean = false;
  @Input('header-fullName') fullName: string = '';

  constructor(private readonly keycloak: KeycloakService,
    private readonly authService: AuthService,
    private translate: TranslateService,
    private userRoleService: UserRoleService,
    private router: Router
    ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn(); 
    const savedRole = localStorage.getItem('selectedRole');
    if (savedRole) {
      this.isOwnerMode = (savedRole === UserRoles.OWNER);
    }       
  }

  public login() {
    this.authService.login();
  }
  
  public logout() {
    this.authService.logout();
  }

  toggle(): void {
    const selectedRole = this.isOwnerMode ? UserRoles.OWNER : UserRoles.OCCUPANT;
    localStorage.setItem('selectedRole', selectedRole);
    this.userRoleService.setSelectedRole(selectedRole);
    this.redirectToUserDashboard(selectedRole);
  }

  redirectToUserDashboard(role: string): void {
    if (role === UserRoles.OWNER) {
      this.router.navigateByUrl(ApplicationRoute.OWNERS_ROUTE.navigationPath + ApplicationRoute.MY_GOODS.navigationPath);
    } else {
      this.router.navigateByUrl(ApplicationRoute.MY_APPLICATION_ROUTE.navigationPath);
    }
  }

  formatFullName(fullName: string): string {
    if (!fullName) return this.translate.instant("menu.my-account-item");
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastNameInitial = nameParts.length > 1 ? nameParts[1].charAt(0) + '.' : '';
    return this.capitalizeFullName(`${firstName} ${lastNameInitial}`);
  }

  capitalizeFullName(fullName: string): string {
    if (!fullName) return '';
    const words = fullName.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return capitalizedWords.join(' ');
  }
}
