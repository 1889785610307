import {Injectable, NgZone} from "@angular/core";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class MatSnackBarService {
    snackBarDefaultConfig: MatSnackBarConfig = {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'custom-snackbar',
        duration: 4000
    };

    constructor(
        private readonly snackbar: MatSnackBar,
        private readonly zone: NgZone,
        private readonly translator: TranslateService
    ) {}

    public successNotify(msg: string = this.translator.instant("snackbar.success"), snackBarConfig: MatSnackBarConfig = this.snackBarDefaultConfig) {
        this.zone.run(() => {
            this.snackbar.open(msg, this.translator.instant('snackbar.close'), snackBarConfig);
        });
    }

    public errorNotify(msg: string = this.translator.instant("snackbar.error"), snackBarConfig: MatSnackBarConfig = this.snackBarDefaultConfig) {
        this.zone.run(() => {
            this.snackbar.open(msg || this.translator.instant("snackbar.error"), this.translator.instant('snackbar.close'), snackBarConfig);
        });
    }
}