<div class="search-bar text-center row d-flex col">
    <div class="col-3">
        <mat-form-field class="filter-field">
            <mat-label>{{ 'homepage.home.postalcode' | translate}} </mat-label>
            <input matInput [formControl]="postalCode" (keydown.enter)="addCode()">
            <span matTextSuffix>
                <mat-icon id="postalcode-icon"> location_on</mat-icon>
            </span>
        </mat-form-field>
        <div *ngIf="selectedCodes.length > 0" class="chip">
            <mat-chip *ngFor="let code of selectedCodes" [removable]="true" (removed)="removeCodeChip(code)">{{ code }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </div>
    </div>

    <div class="col-3">
        <mat-form-field class="filter-field">
            <mat-label>{{ 'homepage.home.property-type' | translate}}</mat-label>
            <input matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addType($event)">
                <mat-option *ngFor="let type of propertyTypesList" [value]="type">{{ 'propertyType.' + type | translate
                    }}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="selectedValues.length > 0">
            <mat-chip *ngFor="let value of selectedValues" [removable]="true"
                (removed)="removeTypeChip(value)">{{value}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </div>
    </div>

    <div class="col-3">
        <mat-form-field appearance="fill" class="filter-field">
            <mat-label>{{ 'homepage.home.price' | translate}}</mat-label>
            <mat-select>
                <mat-option value="one">100 - 200 </mat-option>
                <mat-option value="two">200 - 300 </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-3">
        <button mat-button id="filter-button" (click)="filtersPopupOpen()">
            <mat-icon class="filter-icon  col-1">tune</mat-icon>
        </button>

        <button class="search-button" mat-flat-button (click)="onClick()">
            <mat-icon class="search-icon"> search </mat-icon>
            {{ 'homepage.home.search' | translate }}
        </button>
    </div>
</div>