<form [formGroup]="infoFormGroup">
    <h2 id="info-title">{{'goods.apply-form.stepper.info-title' | translate}}</h2>
    <div class="d-flex col">
        <div class="form-group col-6 m-0 p-0">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label for="fullName">{{ "goods.apply-form.name-and-lastname" | translate }}</mat-label>
                <input matInput formControlName="fullName" id="fullName" type="text" class="form-control">
                <div *ngIf="formControls['fullName'].touched && formControls['fullName'].invalid" class="errors">
                    <div *ngIf="formControls['fullName'].errors && formControls['fullName'].errors['required']">
                        {{ 'error-message.fullName-error' | translate }}</div>
                </div>
            </mat-form-field>
        </div>
        <div class="form-group col-6 m-0 p-0">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label for="phoneNumber">{{ "goods.apply-form.phone" | translate }}</mat-label>
                <input matInput formControlName="phoneNumber" id="phoneNumber" type="text" class="form-control">
                <div *ngIf="formControls['phoneNumber'].touched && formControls['phoneNumber'].invalid" class="errors">
                    <div *ngIf="formControls['phoneNumber'].errors && formControls['phoneNumber'].errors['required']">
                        {{ 'error-message.phoneNumber-error' | translate }}</div>
                </div>
            </mat-form-field>
        </div>
    </div>
    <div class="d-flex col">
        <div class="form-group col-6 m-0 p-0">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label for="email">{{ "goods.apply-form.email" | translate }}</mat-label>
                <input matInput formControlName="email" id="email" type="text" class="form-control">
                <div *ngIf="formControls['email'].touched && formControls['email'].invalid" class="errors">
                    <div *ngIf="formControls['email'].errors && formControls['email'].errors['required']">
                        {{ 'error-message.email-error' | translate }}</div>
                </div>
            </mat-form-field>
        </div>
        <div class="form-group col-6 m-0 p-0">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label for="civilState">{{ "goods.apply-form.civil-state" | translate }}</mat-label>
                <input matInput formControlName="civilState" id="civilState" type="text" class="form-control">
                <div *ngIf="formControls['civilState'].touched && formControls['civilState'].invalid" class="errors">
                    <div *ngIf="formControls['civilState'].errors && formControls['civilState'].errors['required']">
                        {{ 'error-message.civilState-error' | translate }}</div>
                </div>
            </mat-form-field>
        </div>
    </div>
    <div class="form-group col-12 m-0 p-0">
        <mat-form-field appearance="fill" class="w-100">
            <mat-label for="address">{{ "goods.apply-form.address" | translate }}</mat-label>
            <input matInput formControlName="address" id="address" type="text" class="form-control">
            <div *ngIf="formControls['address'].touched && formControls['address'].invalid" class="errors">
                <div *ngIf="formControls['address'].errors && formControls['address'].errors['required']">
                    {{ 'error-message.address-error' | translate }}</div>
            </div>
        </mat-form-field>
    </div>

</form>