import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {GoodCreationService} from '../services/good-creation.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PropertyType} from '../models/enums/property-type.enum';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ReviewPopupComponent} from './review-popup/review-popup.component';
import {Patterns} from 'src/shared/variables/patterns';
import {AppConstants} from 'src/shared/constants/app.constants';
import {BuildingState} from '../models/enums/building-state.enum';
import {KitchenType} from '../models/enums/kitchen-type.enums';


@Component({
  selector: 'app-good-adding',
  templateUrl: './create-good.component.html',
  styleUrls: ['./create-good.component.scss']
})
export class CreateGoodComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatStepper)
  stepper!: MatStepper;

  commonFormGroup!: FormGroup;
  generalFormGroup!: FormGroup;
  interiorExteriorFormGroup!: FormGroup;
  installationsFormGroup!: FormGroup;
  energyFinanceFormGroup!: FormGroup;
  attachmentsFormGroup!: FormGroup;

  propertyTypeValueChangeSubscription?: Subscription;
  numberPattern = Patterns.NUMBER;

  constructor(readonly goodCreationService: GoodCreationService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this.commonFormGroup = this.fb.group({
      propertyType: ['', Validators.required],
      goodTitle: ['', Validators.required],
      address: this.fb.group({
        streetName: ['', Validators.required],
        streetNb: ['', Validators.required],
        postalCode: ['', [Validators.required, Validators.pattern('[-_a-zA-Z0-9]*')]],
        city: ['', [Validators.required,  Validators.pattern('[a-zA-Z ]*')]],
      }),
      description: ['', [Validators.required, Validators.minLength(150), Validators.maxLength(1500)]]
    });
    this.generalFormGroup = this.fb.group({
      disponibility: [new Date(), [Validators.required, this.dateValidator]],
      constructionYear: ['', [Validators.pattern(/^\d{4}$/), this.constructionYearValidator.bind(this)]],
      buildingState: [this.getKeyByEnumValue(BuildingState, BuildingState.UNDEFINED)],
      facadesNumber: ['', [Validators.required, Validators.pattern(/^[0-4]+$/), Validators.min(1), Validators.max(4)]],
      isAnimalAllowed: [''],
    });
    this.interiorExteriorFormGroup = this.fb.group({
      livingSpaceM2: ['', [Validators.required, Validators.pattern(this.numberPattern), this.validateLivingSpace.bind(this)]],
      nbOfRooms: ['', [Validators.required, Validators.pattern(this.numberPattern), this.validateNbOfRooms.bind(this)]],
      kitchenType: [this.getKeyByEnumValue(KitchenType, KitchenType.NON_EQUIPPED)],
      hasAttic: [''],
      hasCellar: [''],
      hasFurnitures: [''],
      landArea: ['', [Validators.pattern(this.numberPattern), this.validateLandArea.bind(this)]],
      hasGarden: [''],
      gardenArea: [''],
      hasTerrace: [''],
      terraceArea: [''],
      parkingPlaces: ['', [Validators.pattern(this.numberPattern), this.validateParkingPlaces.bind(this)]]
    });
    this.installationsFormGroup = this.fb.group({
      hasHandicapAccess: [''],
      hasIntercom: [''],
      hasTvCable: [''],
      hasLift: [''],
    });
    this.energyFinanceFormGroup = this.fb.group({
      energyClass: ['', Validators.required],
      heatingType: ['', Validators.required],
      glazingType: ['', Validators.required],
      other: [''],
      monthlyRent: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      monthlyRentalCharges: ['', Validators.required],
      rentalGuarantee: [''],
    });
    this.attachmentsFormGroup = this.fb.group({
      imageFileIds: [] as number[],
      mainImageFileId: ['']
    });

    const propertyTypeControl = this.commonFormGroup.get('propertyType');
    this.propertyTypeValueChangeSubscription = propertyTypeControl?.valueChanges.subscribe(propertyType => {
        const monthlyRentalCharge = this.energyFinanceFormGroup.get('monthlyRentalCharges');
        if (propertyType ===  PropertyType.APARTMENT) {
          monthlyRentalCharge?.addValidators([Validators.required]);
        } else if(propertyType === PropertyType.HOUSE) {
          monthlyRentalCharge?.clearValidators();
        }
        monthlyRentalCharge?.updateValueAndValidity();
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.goodCreationService.stepper = this.stepper;
    }, 0)
  }

  ngOnDestroy(): void {
    if (this.propertyTypeValueChangeSubscription) {
        this.propertyTypeValueChangeSubscription.unsubscribe();
    }
  }

  fieldIsEmpty(formControlName: string): boolean {
    const control = this.energyFinanceFormGroup.get(formControlName);
    return control ? control.touched && control.value === '' : true;
  }

  reviewFormData() {
    const formData = {
      common: this.commonFormGroup.value,
      general: this.generalFormGroup.value,
      interiorExterior: this.interiorExteriorFormGroup.value,
      installations: this.installationsFormGroup.value,
      energyFinance: this.energyFinanceFormGroup.value,
      attachments: this.attachmentsFormGroup.value
    };

    const dialogRef = this.dialog.open(ReviewPopupComponent, {
      width: '80em',
      data: formData,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  dateValidator(control: any){
    const selectedDate: Date = control.value;
    if (!selectedDate) return { 'invalidDate': true };
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return selectedDate >= today ? null : { 'invalidDate': true };
  }

  constructionYearValidator(control: any) {
    const currentYear = new Date().getFullYear();
    const enteredYear = control.value;
    if (!enteredYear) return null;
    return enteredYear < AppConstants.GOOD_MIN_CONSTRUCTIONYEAR || enteredYear > currentYear ? { 'invalidYear': true } : null;
  }

  validateParkingPlaces(control: any) {
    return this.validateNumberBetween(control, 'invalidParkingPlaces', 0, AppConstants.GOOD_MAX_PARKINPLACES);
  }

  validateLandArea(control: any) {
    return this.validateNumberBetween(control, 'invalidLandArea', 0, AppConstants.GOOD_MAX_LANDAREA);    
  }

  validateLivingSpace(control: any) {
    return this.validateNumberBetween(control, 'invalidLivingSpace', 0, AppConstants.GOOD_MAX_LIVINGSPACEM2);   
  }

  validateNbOfRooms(control: any) {
    return this.validateNumberBetween(control, 'invalidNbOfRooms', 0, AppConstants.GOOD_MAX_NBOFROOMS);    
  }

  private validateNumberBetween(control: any, errorName: string, min: number = 0, max: number) {
    const value = control.value;
    return value !== null && (isNaN(value) || value < min || value > max) ?  { [errorName]: true } : null;
  }

  private getKeyByEnumValue(enumObj: any, enumValue: string): string | undefined {
    const keys = Object.keys(enumObj).filter(key => enumObj[key] === enumValue);
    return keys.length > 0 ? keys[0] : undefined;
  }
}