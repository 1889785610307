import {Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserRoleService } from 'src/app/services/userRole.service';
import { ApplicationRoute } from 'src/shared/constants/application-route';
import { UserRoles } from 'src/shared/enums/user-roles.enum';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnDestroy {

  ApplicationRoute = ApplicationRoute;
  isOwnerMode: boolean = false;
  roleSubscription: Subscription;
  
  constructor(private userRoleService: UserRoleService) {
    this.roleSubscription = this.userRoleService.selectedRole$.subscribe(role => {
      this.isOwnerMode = role == UserRoles.OWNER;
    });
  }

  ngOnDestroy(): void {
    this.roleSubscription.unsubscribe();
  }
}
