import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationRoute } from 'src/shared/constants/application-route';

@Component({
  selector: 'app-creation-validation-popup',
  templateUrl: './creation-validation-popup.component.html',
  styleUrls: ['./creation-validation-popup.component.scss']
})
export class CreationValidationPopupComponent {

  ApplicationRoute = ApplicationRoute;
  goodId!: number;

  constructor(
    public dialogRef: MatDialogRef<CreationValidationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}


  closeDialog(): void {
    this.dialogRef.close();
  }

  get scheduleRoute() {
    this.goodId = this.data.goodId;
    return `${ApplicationRoute.GOODS.navigationPath}/${this.goodId}${ApplicationRoute.SCHEDULE.navigationPath}`;
  }

}