export enum EnergyClass {
    A_PLUS_PLUS = "A++",
    A_PLUS = "A+",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G"
}