<div class="d-flex align-items-start">
    <div class="align-items-start menu">
        <app-navigation-menu></app-navigation-menu>
    </div>

    <div class="d-block" id="property-adding-form">
        <mat-stepper #stepper>
            <mat-step [stepControl]="commonFormGroup">
                <ng-template matStepLabel>{{'property-adding.common' | translate}}</ng-template>
                <app-common-form [commonFormGroup]="commonFormGroup"></app-common-form>
            </mat-step>
            <mat-step [stepControl]="generalFormGroup">
                <ng-template matStepLabel>{{'property-adding.general' | translate}}</ng-template>
                <app-general-form [generalFormGroup]="generalFormGroup"></app-general-form>
            </mat-step>
            <mat-step [stepControl]="interiorExteriorFormGroup">
                <ng-template matStepLabel>{{'property-adding.interior-exterior' | translate}}</ng-template>
                <app-interior-exterior-form [interiorExteriorFormGroup]="interiorExteriorFormGroup"></app-interior-exterior-form>
            </mat-step>
            <mat-step [stepControl]="installationsFormGroup">
                <ng-template matStepLabel>{{'property-adding.installations' | translate}}</ng-template>
                <app-installations-form [installationsFormGroup]="installationsFormGroup"></app-installations-form>
            </mat-step>
            <mat-step [stepControl]="energyFinanceFormGroup">
                <ng-template matStepLabel>{{'property-adding.energy-finance' | translate}}</ng-template>
                <app-energy-finance-form [energyFinanceFormGroup]="energyFinanceFormGroup"></app-energy-finance-form>
            </mat-step>
            <mat-step [stepControl]="attachmentsFormGroup">
                <ng-template matStepLabel>{{'property-adding.attachments' | translate}}</ng-template>
                <app-attachments-form [formGroup]="attachmentsFormGroup"></app-attachments-form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{'property-adding.done' | translate}}</ng-template>
                <app-done-form></app-done-form>
            </mat-step>
        </mat-stepper>

        <div *ngIf="goodCreationService.stepper">
            <button mat-button *ngIf="goodCreationService.stepper.selectedIndex > 0" class="back-button" (click)="goodCreationService.goToPreviousStep()">
                <mat-icon>arrow_back_ios</mat-icon>
                {{'property-adding.back' | translate}}
            </button>
            <button mat-button class="next-button" *ngIf="goodCreationService.stepper.selectedIndex < goodCreationService.stepper.steps.length - 1" (click)="goodCreationService.fillGoodAndGoToNextStep()">
                {{'property-adding.next' | translate}}
                <mat-icon>arrow_forward_ios</mat-icon>
            </button>
            <ng-container *ngIf="goodCreationService.stepper.selectedIndex === goodCreationService.stepper.steps.length - 1">
                <button mat-button type="button" id="reset-button" (click)="goodCreationService.resetCreation()">
                <mat-icon>restart_alt</mat-icon>
                {{'property-adding.reset' | translate}}
            </button>
                <button mat-button id="preview-button" (click)="reviewFormData()">
                <mat-icon>preview</mat-icon>
                {{'property-adding.preview' | translate}}
            </button>
                <button mat-button type="button" id="confirm-button" (click)="goodCreationService.saveGood()">
                <mat-icon>check</mat-icon>
                {{'property-adding.confirm' | translate}}
            </button>
            </ng-container>
        </div>
    </div>
</div>