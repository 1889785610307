import { Component } from '@angular/core';
import { GoodCreationService } from '../../services/good-creation.service';

@Component({
  selector: 'app-done-form',
  templateUrl: './done-form.component.html',
  styleUrls: ['./done-form.component.scss']
})
export class DoneFormComponent {

  constructor(readonly goodCreationService: GoodCreationService) { }
}
