import {Component, Input} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationRoute } from 'src/shared/constants/application-route';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent{
  @Input() isLoggedIn: boolean = false;
  @Input() hasGoods: boolean = false;

  @Input()  
  isOwnerMode!: boolean;

  ApplicationRoute = ApplicationRoute;

  constructor (
    private readonly authService: AuthService
  ) {}


  get myGoodsRoute(): string {
    const ownerId = this.authService.getUserObject().id;
    return `/owners/${ownerId}/my-goods`;
  }
}
