import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LanguageComponent} from "./header/language/language.component";
import {HeaderMenuComponent} from "./header/header-menu/header-menu.component";
import {HeaderComponent} from './header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table'
import {MatMenuModule} from '@angular/material/menu'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatPaginatorModule} from '@angular/material/paginator';
import {FooterComponent} from './footer/footer.component';
import {FooterMenuComponent} from './footer/footer-menu/footer-menu.component';
import {SocialNetworksComponent} from './footer/social-networks/social-networks.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MatSelectModule} from "@angular/material/select";
import {MatNativeDateModule, MatOptionModule, MatPseudoCheckboxModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CreateGoodModule} from "./goods/create-good/create-good.module";
import {GlobalFileService} from "./goods/services/global-file.service";
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {MatRadioModule} from "@angular/material/radio";
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AboutUsComponent} from './about-us/about-us.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {environment} from 'src/environments/environment';
import {GlobalErrorHandler} from "./handlers/global-error.handler";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {TitleCasePipe} from "@angular/common";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";
import {ErrorComponent} from './error/error.component';

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
      keycloak.init({
        config: {
          url: environment.keycloak.config.url,
          realm: environment.keycloak.config.realm,
          clientId: environment.keycloak.config.clientId,
        },
        initOptions: {
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            checkLoginIframe: false,
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
            responseMode: 'fragment',
            flow: 'standard',
        }
      });
  }

@NgModule({
    declarations: [
        LanguageComponent,
        HeaderMenuComponent,
        HeaderComponent,
        AppComponent,
        FooterComponent,
        FooterMenuComponent,
        SocialNetworksComponent,
        AboutUsComponent,
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        MatTableModule,
        MatButtonModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatMenuModule,
        ReactiveFormsModule,
        CreateGoodModule,
        KeycloakAngularModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        FormsModule,
        MatSlideToggleModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        FormsModule,
        CreateGoodModule,
        MatRadioModule,
        GlobalFileService,
        MatPseudoCheckboxModule,
        provideEnvironmentNgxMask(),
        TitleCasePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
