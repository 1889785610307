import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendConstants } from "src/shared/constants/backend.constants";

@Injectable()
export class ApplyFormUploadFileService { 

    static GOODS_ENDPOINT = "goods";
    static CANDIDATES_ENDPOINT = "candidates";

    constructor(private http: HttpClient) { }

    uploadFileOfApplyForm(goodId: number, candidateId: number, fileType: string, formData: FormData): Observable<string> {
        return this.http.post<any>(`${BackendConstants.BACKEND_URL}/${ApplyFormUploadFileService.GOODS_ENDPOINT}/${goodId}/${ApplyFormUploadFileService.CANDIDATES_ENDPOINT}/${candidateId}/files/${fileType}`, formData);
    }
}