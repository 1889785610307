<form [formGroup]="energyFinanceFormGroup">
    <h2>{{'property-adding.energy' | translate}}</h2>
    <div class="d-flex col">
        <mat-form-field appearance="fill" class="col-4">
            <mat-label for="energyClass">{{'property-adding.energy-class' | translate}}</mat-label>
            <mat-select formControlName="energyClass">
                <mat-option *ngFor="let type of energyClasses" [value]="type">
                    {{'option.' + type | translate}}
                </mat-option>
            </mat-select>
            <div *ngIf="fieldIsEmpty('energyClass')" class="errors">
                {{'errors.energy-class-field' | translate}}
            </div>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-4">
            <mat-label for="heatingType">{{'property-adding.heating-type' | translate}}</mat-label>
            <mat-select formControlName="heatingType">
                <mat-option *ngFor="let type of heatingTypes" [value]="type">
                    {{'option.' + type | translate}}
                </mat-option>
            </mat-select>
            <div *ngIf="fieldIsEmpty('heatingType')" class="errors">
                {{'errors.heating-type-field' | translate}}
            </div>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-4">
            <mat-label for="glazingType">{{'property-adding.glazing-type' | translate}}</mat-label>
            <mat-select formControlName="glazingType">
                <mat-option *ngFor="let type of glazingTypes" [value]="type">
                    {{'option.' + type | translate}}
                </mat-option>
            </mat-select>
            <div *ngIf="fieldIsEmpty('glazingType')" class="errors">
                {{'errors.glazing-type-field' | translate}}
            </div>
        </mat-form-field>
    </div>
    <mat-form-field class="form-group d-flex" *ngIf="glazingType === 'other'">
        <mat-label for="other">{{'property-adding.glazing.other' | translate}}</mat-label>
        <input matInput formControlName="other" id="otherField" type="text">
    </mat-form-field>


    <h2>{{'property-adding.finance' | translate}}</h2>
    <div class="d-flex col">
        <mat-form-field class="form-group rent-field col-4">
            <mat-label for="monthlyRent">{{'property-adding.monthly-rent' | translate}}</mat-label>
            <input matInput formControlName="monthlyRent" id="monthlyRent" type="text">
            <span matTextSuffix>€</span>
            <div *ngIf="fieldIsEmpty('monthlyRent')" class="errors">
                {{'errors.monthly-rent-field' | translate}}
            </div>
        </mat-form-field>
        <mat-form-field class="form-group charges-field col-4">
            <mat-label for="monthlyRentalCharges">{{'property-adding.monthly-rental-charges' | translate}}</mat-label>
            <input matInput formControlName="monthlyRentalCharges" id="monthlyRentalCharges" step="0.01" pattern="^\d+(?:\.\d{1,2})?$" type="text">
            <span matTextSuffix>€</span>
            <div *ngIf="!energyFinanceFormGroup.get('monthlyRentalCharges')?.pristine && !energyFinanceFormGroup.get('monthlyRentalCharges')?.valid" class="errors">
                {{'errors.rental-charges-field' | translate}}
            </div>

        </mat-form-field>
        <mat-form-field class="form-group guarantee-field col-4">
            <mat-label for="rentalGuarantee">{{'property-adding.rental-guarantee' | translate}}
            </mat-label>
            <mat-select formControlName="rentalGuarantee">
                <mat-option *ngFor="let guarantee of rentalGuarantees" [value]="guarantee">
                    {{guarantee}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>